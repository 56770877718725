import React from 'react';
import styled from 'styled-components';

/** Components */
import TestimonialItem from '../../components/TestimonialItem';

/** Data */
import { testimonialData } from './testimonialData';

/** Constants */
import { device } from '../../styles/constants';

/**
 *
 * TestimonialList
 *
 */

const ListWrapper = styled.ul`
  width: 100%;
  padding: 0;
  margin-bottom: 40px;

  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;

  @media ${device.laptop} {
    height: 1143px;
  }

  @media ${device.laptopL} {
    height: 1035px;
  }

  @media ${device.desktop} {
    height: 1075px;
  }

  & .testimonial-item {
    max-width: 100%;

    margin-top: 12%;
    margin-bottom: 12%;

    @media ${device.mobileL} {
      margin: 40px 0;
    }

    @media ${device.laptop} {
      max-width: 31%;
    }

    @media ${device.desktop} {
      margin: 50px 0;
    }
  }
`;

const TestimonialList = () => (
  <ListWrapper>
    {testimonialData.map(({ id, ...item }) => (
      <TestimonialItem key={id} {...item} />
    ))}
  </ListWrapper>
);

export default TestimonialList;
