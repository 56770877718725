import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import IconLogoDark from '../../../assets/img/logo-dark.svg';
import IconLogoLight from '../../../assets/img/logo-light.svg';

const Wrapper = styled.div`
  @media (min-width: 767px) {
    width: ${({ variant }) => (variant === 'dark' ? 212 : 159)}px;
    svg {
      width: 100%;
      height: auto;
    }
  }
`;

export default function Logo({ variant = 'dark' }) {
  return <Wrapper variant={variant}>{variant === 'dark' ? <IconLogoDark /> : <IconLogoLight />}</Wrapper>;
}

Logo.propTypes = {
  variant: PropTypes.oneOf(['light', 'dark']),
};
