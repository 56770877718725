/*
 *
 * LoginProvider reducer
 *
 */
import { LOGIN_SUCCESS, LOGIN_FILED } from './constants';

export const initialState = {
  loginResult: {
    isSuccess: false,
    errorMessage: '',
  },
};

function LoginProviderReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        loginResult: { isSuccess: true, errorMessage: '' },
      };
    case LOGIN_FILED:
      return {
        ...state,
        loginResult: {
          isSuccess: false,
          errorMessage: action.payload,
        },
      };
    default:
      return state;
  }
}

export default LoginProviderReducer;
