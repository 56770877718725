/*
 *
 * SignUpProvider reducer
 *
 */

import { SIGNUP_SUCCESS, SIGNUP_FILED } from './constants';

export const initialState = {
  signupResult: {
    isSuccess: false,
    errorMessage: '',
  },
};

function SignupProviderReducer(state = initialState, action) {
  switch (action.type) {
    case SIGNUP_SUCCESS:
      return {
        ...state,
        signupResult: { isSuccess: true, errorMessage: '' },
      };
    case SIGNUP_FILED:
      return {
        ...state,
        signupResult: {
          isSuccess: false,
          errorMessage: action.payload,
        },
      };
    default:
      return state;
  }
}

export default SignupProviderReducer;
