/*
 *
 * Reducer
 *
 */
import { REQUEST_UPDATE_PROFILE, UPDATE_PROFILE_SUCCESS, UPDATE_PROFILE_FILED } from './constants';

export const initialState = {
  updateProfileResult: {
    isSuccess: false,
    errorMessage: '',
    requestInProgress: false,
  },
};

function UpdateProfileProviderReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_UPDATE_PROFILE:
      return {
        ...state,
        updateProfileResult: {
          isSuccess: false,
          errorMessage: '',
          requestInProgress: true,
        },
      };
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        updateProfileResult: {
          isSuccess: true,
          errorMessage: '',
          requestInProgress: false,
        },
      };
    case UPDATE_PROFILE_FILED:
      return {
        ...state,
        updateProfileResult: {
          isSuccess: false,
          errorMessage: action.payload,
          requestInProgress: false,
        },
      };
    default:
      return state;
  }
}

export default UpdateProfileProviderReducer;
