import apisauce from 'apisauce';
export const landingApi = apisauce.create({
  baseURL: `${process.env.API_URL || ''}/api/landing-iq-test`,
  withCredentials: true,
});
export const charge = data => landingApi.post('/charge', data);
export const singUp = data => landingApi.post('/sign-up', data);
export const resendEmailForLandingPayer = id => landingApi.post('/resend-email-for-landing-payer', { id });
export const isLandingUserExists = email => landingApi.post('/is-landing-user-exists', { email });
export const checkUserTaxRate = data => landingApi.post('/check-user-tax-rate', data);

export const userApi = apisauce.create({
  baseURL: `${process.env.API_URL || ''}/api/user`,
  withCredentials: true,
});
export const subscribe = data => userApi.post('/subscribe', data);
export const moveLandingUser = data => userApi.post('/move-landing-user', data);
export const openHtml = data =>
  landingApi.get('/open-html', data).then(resp => {
    const response = resp.data;
    return response;
  });

export const limeLightApi = apisauce.create({
  withCredentials: true,
});
export const getPaymentToken = data =>
  limeLightApi.post('https://brainable.limelightcrm.com/api/v2/tokenize_payment', data, {
    withCredentials: false,
    headers: {
      Authorization: `Basic ${process.env.LIMELIGHT_CRED}`,
      'Content-Type': 'application/json',
    },
  });
export const getBraintreeToken = async campaignType => {
  let campaignId = null;
  // TODO: need refactoring
  const variant = 'control';
  if (variant) {
    const campaignsByVariant = {
      0: '172',
      1: '234',
      2: '172',
      3: '234',
      4: '236',
      5: '238',
      control: '172',
      '19-95_trial': '234',
      '27-88_no_trial': '172',
      '19-95_no_trial': '234',
      '14-95_no_trial': '236',
      '9-95_no_trial': '238',
    };
    campaignId = campaignsByVariant[variant];
  } else {
    switch (campaignType) {
      case 'QS':
        campaignId = process.env.LIMELIGHT_BRAINTREE_CAMPAIGN_QS;
        break;
      case 'BMS':
        campaignId = process.env.LIMELIGHT_BRAINTREE_CAMPAIGN_BMS;
        break;
      case 'MS':
        campaignId = process.env.LIMELIGHT_BRAINTREE_CAMPAIGN_MS;
        break;
      case 'SS':
        campaignId = process.env.LIMELIGHT_BRAINTREE_CAMPAIGN_SS;
        break;
      default:
        campaignId = process.env.LIMELIGHT_BRAINTREE_CAMPAIGN_MS;
    }
  }

  const data = {
    alt_pay_type: 'paypal',
    campaign_id: campaignId,
  };

  const resp = await limeLightApi.post('https://brainable.limelightcrm.com/api/v1/get_alternative_provider', data, {
    withCredentials: false,
    headers: {
      Authorization: `Basic ${process.env.LIMELIGHT_CRED}`,
      'Content-Type': 'application/json',
    },
  });

  if (resp.ok && resp.data && parseInt(resp.data.response_code, 10) === 100) {
    return resp.data.server_token;
  }

  return '';
};

export const getSalePrice = type => {
  let offerId = null;
  switch (type) {
    case 'QS':
      offerId = process.env.LIMELIGHT_OFFER_QS;
      break;
    case 'BMS':
      offerId = process.env.LIMELIGHT_OFFER_BMS;
      break;
    case 'MS':
      offerId = process.env.LIMELIGHT_OFFER_MS;
      break;
    case 'SS':
      offerId = process.env.LIMELIGHT_OFFER_SS;
      break;
    default:
      offerId = process.env.LIMELIGHT_OFFER_MS;
  }

  return limeLightApi
    .post(
      'https://brainable.limelightcrm.com/api/v1/offer_view',
      {
        offer_id: offerId,
      },
      {
        withCredentials: false,
        headers: {
          Authorization: `Basic ${process.env.LIMELIGHT_CRED}`,
          'Content-Type': 'application/json',
        },
      },
    )
    .then(resp => {
      let amount = '1.99';
      if (resp.data.data[0] && resp.data.data[0].trial) {
        amount = resp.data.data[0].trial.price.custom_price || amount;
      }
      return amount;
    });
};
