/**
 *
 * Header
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';
import styled from 'styled-components';

import heroBg from '../../assets/img/home_page/bgtop.png';
import TestLogo from '../../assets/img/logo/landing-logo1.png';
import Dropdown from './Dropdown';
import { ButtonNoOutlineBlack, ButtonOutlinedBlack, ButtonFilledOrange } from '../DesignSystem/Buttons';
import Burger from './Burger';

/** Constants */
import { device } from '../../styles/devices';
import palette from '../../styles/palette';

/** Styles */
import '../../assets/css/style.css';
import '../../assets/css/page.css';
import Logo from '../atoms/Logo';

const Background = styled.div`
  background-image: ${({ src }) => (src ? `url(${src})` : 'none')};
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
  position: absolute;
  width: 100vw;
  top: 0px;
  left: 0px;
  height: 1400px;
  max-height: 100%;
`;

const StyledNavbar = styled(Navbar)`
  min-height: 89px;

  @media ${device.laptop} {
    min-height: 99px;
  }
  button,
  a {
    font-size: 16px;
    line-height: 22px;
    height: 47px;
  }
  &.navbar {
    top: 0;
    border: none;
  }

  &.start-iq-test-page .nav-item .nav-link {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;

    /* identical to box height, or 140% */
    text-align: center;

    /* BLack blue */
    color: ${palette.white};
  }
  &.start-iq-test-page {
    min-height: 56px;
    background-color: #113861;
  }
`;

const StyledNavbarBrand = styled(NavbarBrand)`
  z-index: 10000;
  &.navbar-brand img {
    width: 34px;
    height: 33px;
  }

  @media ${device.laptop} {
    margin-top: 9px;
    margin-bottom: 10px;
    &.navbar-brand img {
      width: 51px;
      height: 49px;
    }
  }
`;

const StyledNavbarTestBrand = styled(NavbarBrand)`
  z-index: 10000;
  margin-top: auto;
  margin-bottom: auto;
  height: 56px;
`;

const BurgerIcon = styled(Burger)``;

const EmailText = styled.span`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;

  /* Orange */
  color: ${palette.accent};

  /** Flex */
  align-self: flex-start;

  @media ${device.laptop} {
    display: none;
  }
`;

const Collapsible = styled(Collapse)`
  padding-top: 34px;
  padding-bottom: 48px;
  padding-left: 42px;
  padding-right: 42px;

  display: flex;
  flex-direction: column;

  & .nav-item .nav-link,
  & a.dropdown-item {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;

    /* identical to box height, or 140% */
    text-align: center;

    /* BLack blue */
    color: ${palette.blackBlue};
  }

  @media (max-width: 991px) {
    background: white !important;
  }

  @media ${device.laptop} {
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;

    /** Flex */
    flex-direction: row;
    justify-content: flex-end;

    &.navbar-collapse-auth {
      top: 0px;
      height: 100%;
      margin-left: 20%;
      justify-content: space-between;
    }

    & .nav-item .nav-link {
      color: ${palette.white};
      display: initial;
      vertical-align: middle;
    }
  }

  @media (min-width: 1300px) {
    margin-right: -60px;
    padding-right: 42px;
  }
`;

const AuthNav = styled(Nav)`
  padding-top: 58px;

  & .logout {
    margin-top: 94px;
  }

  @media ${device.laptop} {
    padding-top: 0;

    & .nav-link {
      padding: 0;
      margin: 0.5rem 1rem;
    }

    & .nav-items-mobile-wrapper {
      display: none;
    }
  }
`;

const AuthToggler = styled(NavbarToggler)`
  height: 60px;
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  &.navbar-toggler {
    position: relative;
    padding-left: 16px;
    padding-right: 16px;
    margin-right: 0;
    font-size: 2rem;
  }

  &.navbar-toggler.collapse-shown:before {
    content: '';
    position: absolute;
    border-radius: 50%;
    right: 0;
    left: -30px;
    bottom: 0;
    top: 10px;
    border: 10px solid transparent;
    border-bottom-color: ${palette.white};
    width: 40px;
    height: 40px;
    transform: rotate(-45deg);
  }

  color: ${palette.white};

  &.collapse-shown {
    color: ${palette.blackBlue};
    background-color: ${palette.white};
  }
`;

const NotAuthToggler = styled(NavbarToggler)`
  height: 64px;
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  &.navbar-toggler {
    position: relative;
    padding-left: 16px;
    padding-right: 16px;
    margin-right: 0;
    font-size: 2rem;
  }

  &.navbar-toggler.collapse-shown:before {
    content: '';
    position: absolute;
    border-radius: 50%;
    right: 0;
    left: -30px;
    bottom: 0;
    top: 10px;
    border: 10px solid transparent;
    border-bottom-color: ${palette.white};
    width: 40px;
    height: 40px;
    transform: rotate(-45deg);
  }

  color: ${palette.blackBlue};

  &.collapse-shown {
    background-color: ${palette.white};
  }
`;

const TestLogoWrapper = styled.img`
  width: 142px !important;
  height: 24px !important;
`;

const SupportNavLink = styled(NavLink)`
  font-weight: 400 !important;
  font-family: 'Open Sans' !important;
  padding: 12px 0px !important;
  font-size: 15px !important;
  @media (max-width: 992px) {
    font-size: 13.2px !important;
  }
`;

class Header extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(state => ({
      isOpen: !state.isOpen,
    }));
  }

  render() {
    const { userEmail, isStatic, withBackground, iqTestPage } = this.props;
    const { isOpen } = this.state;

    if (iqTestPage) {
      return (
        <StyledNavbar className="navbar navbar-expand-lg navbar-blue start-iq-test-page" data-navbar="static" expand="lg">
          <div className="container">
            <StyledNavbarTestBrand href="/">
              <TestLogoWrapper src={TestLogo} alt="logo" />
            </StyledNavbarTestBrand>
            <Nav className="navbar-left" navbar>
              <NavItem>
                <SupportNavLink href="/support-center">Support</SupportNavLink>
              </NavItem>
            </Nav>
          </div>
        </StyledNavbar>
      );
    }

    if (userEmail) {
      return (
        <StyledNavbar className="navbar navbar-expand-lg navbar-blue" expand="lg">
          <div className="container">
            <StyledNavbarBrand href="/">
              <Logo variant="light" />
            </StyledNavbarBrand>
            <AuthToggler onClick={this.toggle} className={`${isOpen ? 'collapse-shown' : ''}`}>
              <BurgerIcon fill={!isOpen ? '#fff' : ''} />
            </AuthToggler>

            <Collapsible timeout={0} isOpen={isOpen} navbar className="navbar navbar-collapse navbar-collapse-auth">
              <EmailText>{userEmail}</EmailText>
              <AuthNav className="navbar-left" navbar>
                <NavItem>
                  <NavLink href="/brain-profile">Brain profile</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/games?filter=all">Games</NavLink>
                </NavItem>
                <div className="nav-items-mobile-wrapper">
                  <NavItem>
                    <NavLink href="/dashboard">Dashboard</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="/account">Account</NavLink>
                  </NavItem>
                  <NavItem className="logout">
                    <NavLink href="/signout">Logout</NavLink>
                  </NavItem>
                </div>
              </AuthNav>
              <Dropdown userEmail={userEmail} />
            </Collapsible>
          </div>
        </StyledNavbar>
      );
    }

    return (
      <>
        {withBackground && <Background src={heroBg} loading="lazy" />}
        <StyledNavbar className="navbar" data-navbar={isStatic ? 'static' : 'absolute'} expand="lg">
          <div className="container">
            <StyledNavbarBrand href="/">
              <Logo />
            </StyledNavbarBrand>
            <NotAuthToggler onClick={this.toggle} className={`${isOpen ? 'collapse-shown' : ''}`}>
              <BurgerIcon />
            </NotAuthToggler>
            {/* TODO make a margin  here } */}
            <Collapsible timeout={0} isOpen={isOpen} navbar className="navbar navbar-collapse">
              <Nav className="navbar-left nav-landing" navbar>
                <NavItem>
                  <ButtonNoOutlineBlack as={Link} to="/support-center">
                    Support
                  </ButtonNoOutlineBlack>
                </NavItem>
                <NavItem>
                  <ButtonOutlinedBlack as={Link} to="/login">
                    Log in
                  </ButtonOutlinedBlack>
                </NavItem>
                <NavItem>
                  <ButtonFilledOrange as={Link} to="/signup">
                    Get Started
                  </ButtonFilledOrange>
                </NavItem>
              </Nav>
            </Collapsible>
          </div>
        </StyledNavbar>
      </>
    );
  }
}

Header.propTypes = {
  userEmail: PropTypes.string,
  iqTestPage: PropTypes.bool,
  withBackground: PropTypes.bool,
  isStatic: PropTypes.bool,
};

Header.defaultProps = {
  withBackground: true,
};

export default Header;
