/**
 *
 * Footer
 *
 */

import React from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import LoggedOutFooter from './LoggedOutFooter/LoggedOutFooter';
import LoggedInFooter from './LoggedInFooter/LoggedInFooter';
import TestFooter from './TestFooter';

const Footer = props => {
  const copyrightText = `${new Date().getFullYear()} Brainable DBA ATHPAY.NET.`;
  if (props.testPage) {
    return <TestFooter copyrightText={copyrightText} />;
  }
  if (props.auth.isEmpty) {
    return <LoggedOutFooter copyrightText={copyrightText} />;
  }
  return <LoggedInFooter copyrightText={copyrightText} />;
};

Footer.propTypes = {
  testPage: PropTypes.bool,
  auth: PropTypes.object,
};

const mapStateToProps = state => ({
  auth: state.firebase.auth,
});

const withConnect = connect(mapStateToProps);

export default withConnect(Footer);
