import PropTypes from 'prop-types';
import React from 'react';
import { Progress } from 'reactstrap';
import styled from 'styled-components';
import './style.css';

const Title = styled.h2`
  font-size: ${props => (props.titleStyle === 2 ? '16px' : '26px')};
  margin-top: ${props => (props.titleStyle === 2 ? '20px' : '0px')};
  font-weight: 400 !important;
  @media (max-width: 767.98px) {
    font-size: 16px;
    line-height: 1.3;
    margin-top: 10px;
  }
  @media (max-width: 361px) {
    font-size: 16px;
    line-height: 1.2;
    margin-top: 8px;
  }
`;
const Description = styled.div`
  font-size: 22px;
  margin-bottom: 10px;
  font-weight: 300;
  color: #212529;
  @media (max-width: 767.98px) {
    font-size: 16px;
  }
`;

const Task = styled.div`
  margin-top: 10px;
  font-size: 18px;
  color: #212529;
`;

const IS_DEV = process.env.NODE_ENV === 'development';

class Loading extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      description: null,
      task: null,
      loaded: 0,
    };

    this.startInitialLoading = this.startInitialLoading.bind(this);
    this.categoryLoading = this.categoryLoading.bind(this);
  }

  componentDidMount() {
    if (this.props.onInitialLoadComplete) {
      this.startInitialLoading();
    } else {
      this.categoryLoading();
    }
  }

  categoryLoading() {
    const { categoryLoaded } = this.props;
    const { loaded } = this.state;
    if (categoryLoaded) {
      return this.setState({ loaded: 100 });
    }

    if (loaded <= 100) {
      this.setState({ loaded: loaded + 3 });
      return setTimeout(this.categoryLoading, IS_DEV ? 0 : 100);
    }

    this.setState({ loaded: 100 });
    return this.props.onCategoryLoadComplete();
  }

  startInitialLoading() {
    const { loaded } = this.state;
    const { isVocab, isPersonality } = this.props;
    if (isVocab) {
      this.setState({ description: 'Your Vocab test is being prepared' });
    } else if (isPersonality) {
      this.setState({ description: 'Your Personality test is being prepared' });
    } else {
      this.setState({ description: 'Your IQ test is being prepared' });
    }

    let task = null;
    if (loaded < 15) {
      task = 'Executing Commands';
    } else if (loaded < 30) {
      task = 'Accepting Requests';
    } else if (loaded < 30) {
      task = 'Updated Parameters';
    } else if (loaded < 85) {
      task = 'Loading Questions';
    } else if (loaded <= 100) {
      task = 'Loading Questions';
    }
    if (loaded <= 101) {
      this.setState({ loaded: loaded + 1, task });
      return setTimeout(this.startInitialLoading, IS_DEV ? 0 : 100);
    }

    this.setState({ loaded: 100, task, description: null });
    return this.props.onInitialLoadComplete();
  }

  render() {
    const { title, titleStyle } = this.props;
    const { description, task, loaded } = this.state;
    const transitionEffect = loaded < 100 ? '' : 'no-transition';
    return (
      <div>
        <Title className="noselect" titleStyle={titleStyle}>
          {title}
        </Title>
        <Description className="noselect">{description}</Description>
        <Progress color="blue-progress" value={loaded} max={100} barClassName={transitionEffect} />
        <Task className="noselect">{task}</Task>
      </div>
    );
  }
}

Loading.propTypes = {
  title: PropTypes.string.isRequired,
  titleStyle: PropTypes.number,
  categoryLoaded: PropTypes.bool,
  onInitialLoadComplete: PropTypes.func,
  onCategoryLoadComplete: PropTypes.func,
  isVocab: PropTypes.bool,
  isPersonality: PropTypes.bool,
};

export default Loading;
