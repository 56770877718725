/**
 *
 * StartModal
 *
 */

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Modal, ModalBody } from 'reactstrap';
import AnimatedButton from '../../AnimatedButton';

const Title = styled.h1`
  font-size: 24px;
  text-align: center;
  color: #212529;
  @media (min-width: 768px) {
    font-size: 32px;
  }
`;

const TitleNotice = styled.h1`
  font-size: 24px;
  text-align: center;
  background-color: #113861;
  color: #fff;
  margin: -20px -20px 0px;
  @media (min-width: 768px) {
    margin: -20px -40px 0px -40px;
    font-size: 32px;
  }
  padding: 20px;
  @media (min-width: 768px) {
    padding: 20px 40px 20px 40px;
  }
`;

const Line = styled.p`
  font-family: Montserrat, sans-serif !important;
  font-size: 0.9375rem;
  font-weight: 300;
  line-height: 1.9;
  color: #757575;
  margin: 10px 0;
  text-align: center;
  color: #212529;
  @media (min-width: 768px) {
    margin: 20px 0;
  }
  @media (max-width: 400px) {
    line-height: 14px;
  }
`;

const Button = styled.button`
  display: inline-block;
  position: relative;
  background-color: #82c76b;
  z-index: 1;
  font-size: 18px;
  white-space: nowrap;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 7px 32px 0px;
  font-weight: 500;
  color: rgb(255, 255, 255) !important;
  border-radius: 50px;
  padding: 18px 30px;
  min-width: 200px;
  border-width: 3px;
  border-style: solid;
  border-color: #82c76b;
  border-image: initial;
  transition: all 0.1s linear 0s;
  cursor: pointer;
  @media (min-width: 360px) {
    font-size: 20px;
    padding: 18px 34px;
  }
  @media (min-width: 768px) {
    font-size: 23px;
  }
`;

const ModalBodyStyled = styled(ModalBody)`
  padding: 20px;
  @media (min-width: 768px) {
    padding: 20px 40px 50px 40px;
  }
`;

const StartModal = ({ isOpen, questionNumber, questionsTimer, onPress, testType = 'Сertified', showInfoModal = true }) => {
  const [showSecondModal, triggerSecondModal] = useState(false);

  return showSecondModal ? (
    <Modal contentClassName="modal-content mt-6 align-self-start" isOpen={isOpen} size="lg">
      <ModalBodyStyled className="noselect green">
        <TitleNotice className="noselect">Notice</TitleNotice>
        <Line>This quiz is provided for entertainment purposes only: it&apos;s not an official IQ test and your score will not qualify you for Mensa.</Line>
        <Line>
          The following IQ test is designed to test non-verbal intelligence while minimizing cultural or educational biases. It&apos;s a culture fair IQ test
          that will measure your intellectual ability, entertain you infinitely, and tie your brain in a thousand knots! After finishing this test you will
          receive a FREE score delivered by email. You will also have the option to purchase the full results for $1.99. You may not use our site or the
          information we provide unless you agree to our{' '}
          <Link to="/terms-of-use" target="_blank">
            Terms of Use
          </Link>{' '}
          and agree to use our site for entertainment purposes only.
        </Line>

        <div className="text-center">
          <Button className="noselect" onClick={onPress}>
            I Agree
          </Button>
        </div>
      </ModalBodyStyled>
    </Modal>
  ) : (
    <Modal contentClassName="modal-content mt-6 align-self-start" isOpen={isOpen} size="lg">
      <ModalBodyStyled className="noselect">
        <Title className="noselect">
          Official BRI
          <sup>TM</sup> {testType} IQ Test:
        </Title>
        <Line>
          1. You have {questionsTimer} minutes to answer {questionNumber} multiple choice questions
        </Line>
        <Line>
          2. Choose the answer for each question
          <span style={{ whiteSpace: 'nowrap' }}> (A,B,C or D)</span>
        </Line>
        <Line>3. Skip a question if you’re stuck, and come back to it later</Line>
        <Line className="text-center">Good luck!</Line>

        <div className="text-center">
          <AnimatedButton className="noselect" onClick={showInfoModal ? () => triggerSecondModal(true) : onPress}>
            START {testType} TEST &gt;&gt;
          </AnimatedButton>
        </div>
      </ModalBodyStyled>
    </Modal>
  );
};

StartModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onPress: PropTypes.func.isRequired,
  questionNumber: PropTypes.number.isRequired,
  questionsTimer: PropTypes.number.isRequired,
  testType: PropTypes.string,
  showInfoModal: PropTypes.bool,
};

export default StartModal;
