import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { H1, H4 } from '../DesignSystem/Headers';
import { ButtonFilledOrangeLarge } from '../DesignSystem/Buttons';
import { deviceMax, device } from '../../styles/constants';
import palette from '../../styles/palette';

const Wrapper = styled.div`
  margin-top: 90px;
  display: flex;
  flex-direction: column;

  position: relative;
  @media ${device.tablet} {
    margin-top: 131px;
  }
  @media ${device.laptop} {
    margin-top: 73px;
    flex-direction: row;
  }
`;

const Content = styled.div`
  padding-top: 17px;
  position: relative;
  @media ${device.laptop} {
    width: 48%;
    padding-right: 15px;
  }
  @media ${device.laptop} {
    padding-top: 49px;
  }
  @media ${device.laptop} {
    padding-top: 85px;
  }
`;

const Title = styled(H1)`
  margin-bottom: 20px;
  text-align: left;
  color: ${palette.blackBlue};
  @media ${deviceMax.tablet} {
    text-align: left;
    font-size: 48px;
    line-height: 54px;
  }

  @media ${device.mobiles} {
    text-align: left;
  }
`;

const SubTitle = styled(H4)`
  margin-bottom: 15px;
  color: ${palette.blackBlue};
  @media ${device.mobiles} {
    text-align: left;
  }
`;

const Description = styled.p`
  color: ${palette.blackBlue};
`;

const DescriptionWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;
  p:last-child {
    margin-bottom: 0;
  }

  @media ${device.tablet} {
    width: 83.33%;
    margin-left: auto;
    margin-right: auto;
  }
  @media ${device.laptop} {
    width: unset;
  }
`;

const ButtonSet = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -40px;
  @media ${device.tablet} {
    margin-top: 30px;
  }
`;

export const Button = styled(ButtonFilledOrangeLarge)`
  margin-top: 10px;
`;

const ImageWrapper = styled.div`
  min-height: 29vh;
  max-width: 452px;
  width: 100%;
  margin: 0 auto;
  svg,
  img {
    width: 106%;
    max-width: unset;
    height: auto;
    margin-left: -3%;
  }

  @media ${device.laptop} {
    width: 52%;
    padding-top: 30px;
    svg,
    img {
      width: 100%;
      margin: 0;
    }
  }
  @media ${device.laptop} {
    /* min-height: 483px; */
  }
  @media ${device.laptopL} {
    min-height: 452px;
  }
  @media (min-width: 1300px) {
    min-height: 506px;
    svg,
    img {
      width: 112%;
      margin-left: 2%;
    }
  }
`;

const HeroSection = ({ className, image, headerText, subheaderText, description, renderButtons }) => {
  const descriptionEl = useMemo(
    () => {
      if (!description) return null;
      // eslint-disable-next-line react/no-array-index-key
      if (Array.isArray(description)) return description.map((item, idx) => <Description key={idx}>{item}</Description>);

      return <Description>{description}</Description>;
    },
    [description],
  );

  return (
    <Wrapper className={className}>
      <Content className="hero-section-content">
        <Title>{headerText}</Title>
        {subheaderText && <SubTitle>{subheaderText}</SubTitle>}
        {descriptionEl && <DescriptionWrapper className="d-none d-lg-block">{descriptionEl}</DescriptionWrapper>}
        <ButtonSet className="d-none d-lg-block">{renderButtons && renderButtons()}</ButtonSet>
      </Content>

      {image && <ImageWrapper>{image}</ImageWrapper>}
      {descriptionEl && <DescriptionWrapper className="d-lg-none">{descriptionEl}</DescriptionWrapper>}
      <ButtonSet className="d-lg-none">{renderButtons && renderButtons()}</ButtonSet>
    </Wrapper>
  );
};

HeroSection.propTypes = {
  className: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  image: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  headerText: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.string]),
  subheaderText: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.string]),
  renderButtons: PropTypes.func,
};

export default HeroSection;
