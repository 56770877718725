import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { QUESTION_TYPES, VOCAB_QUESTION_TYPES } from './constants';
/**
 * Direct selector to the iqTest state domain
 */

export const selectIqTestDomain = state => state.iqTest || initialState;

/**
 * Default selector used by IqTest
 */

export const selectCurrentQuestion = createSelector(selectIqTestDomain, substate => substate.currentQuestion);

export const selectQuestionTypeId = createSelector(selectCurrentQuestion, selectIqTestDomain, (currentQuestion, state) => {
  if (!state.testConfigs) {
    return 0;
  }

  let categoryNumber = -1;
  let totalCounter = 0;
  try {
    Object.values(state.testConfigs.parts).forEach(value => {
      if (currentQuestion > totalCounter) {
        totalCounter += value;
        categoryNumber += 1;
      } else {
        throw new Error();
      }
    });
  } catch (e) {
    return categoryNumber;
  }

  return categoryNumber;
});

export const selectQuestionType = createSelector(selectQuestionTypeId, questionTypeId => QUESTION_TYPES[questionTypeId]);
export const selectVocabQuestionType = createSelector(selectQuestionTypeId, questionTypeId => VOCAB_QUESTION_TYPES[questionTypeId]);
// TODO: use commented version if number of questions in section differs from 4
export const selectQuestionTypeNumber = createSelector(
  selectCurrentQuestion,
  // selectQuestionTypeId,
  // selectQuestionType,
  // selectIqTestDomain,
  (
    currentQuestion,
    // , questionTypeId, questionType, state
  ) =>
    // if (!state.testConfigs) {
    //   return 1;
    // }
    // let questionTypeNumber = currentQuestion;
    // try {
    //   Object.entries(state.testConfigs.parts).forEach(([category, value]) => {
    //     if (questionType !== category) {
    //       questionTypeNumber -= value;
    //     } else {
    //       throw new Error();
    //     }
    //   });
    // } catch (e) {
    //   return questionTypeNumber;
    // }
    currentQuestion % 4 || 4,
);

export const selectStarted = createSelector(selectIqTestDomain, state => state.started);

export const selectAnswers = createSelector(selectIqTestDomain, state => state.answers);
