/*
 *
 * GamesProvider reducer
 *
 */

import { GAMES_DATA_LOADED } from './constants';

export const initialState = {
  gamesData: [],
};

function GamesProviderReducer(state = initialState, action) {
  switch (action.type) {
    case GAMES_DATA_LOADED:
      return {
        ...state,
        gamesData: action.payload,
      };
    default:
      return state;
  }
}

export default GamesProviderReducer;
