import { call, put, takeLatest } from 'redux-saga/effects';
import { fail, processing, success } from './actions';
import { LANDING_RESEND_EMAIL } from './constants';
import { resendEmailForLandingPayer } from '../../utils/api';

export function* resendEmailSaga(action) {
  try {
    yield put(processing());
    const { id } = action.payload;
    const resp = yield call(resendEmailForLandingPayer, id);
    if (resp.data.status === 'success') {
      yield put(success());
    } else {
      yield put(fail(resp.data.message));
    }
  } catch (error) {
    yield put(fail(error.message));
  }
}

export default function* watchLoginRequest() {
  yield takeLatest(LANDING_RESEND_EMAIL, resendEmailSaga);
}
