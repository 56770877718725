/*
 *
 * GameProvider reducer
 *
 */

import { GAME_DATA_LOADED, GAME_DATA_LOAD_ERROR, SAVE_GAME_RESULT_ERROR, GAME_RESULT_SAVED } from './constants';

export const initialState = {
  gameData: null,
  saveResult: {
    isSuccess: false,
    message: '',
  },
  loadResult: {
    isSuccess: false,
    message: '',
  },
};

function GameProviderReducer(state = initialState, action) {
  switch (action.type) {
    case GAME_DATA_LOADED:
      return {
        ...state,
        gameData: action.payload,
        loadResult: { isSuccess: true, message: '' },
      };
    case GAME_DATA_LOAD_ERROR:
      return {
        ...state,
        loadResult: { isSuccess: false, message: 'Can not load game' },
      };
    case SAVE_GAME_RESULT_ERROR:
      return {
        ...state,
        saveResult: { isSuccess: false, message: 'Can not save result' },
      };
    case GAME_RESULT_SAVED:
      return {
        ...state,
        saveResult: { isSuccess: true, message: 'Result saved' },
      };
    default:
      return state;
  }
}

export default GameProviderReducer;
