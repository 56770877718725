import { useEffect } from 'react';

import { setCookie } from '../cookies';
import { isSearchEngine } from '../isSearchEngine';

const PARAM_NAMES = [
  'afid',
  'gclid',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'utm_term',
  'aic', // ReferralCandy referral ID
];

/** Saves traffic query attributes to cookies when user opens the website */
export const useTrafficParamsSaver = () => {
  useEffect(() => {
    // Parse query string
    const entries = new URLSearchParams(window.location.search).entries();
    const object = Object.fromEntries(entries);

    // If page doesn't have "afid" (ad campaign id) and user came from search engine, set "afid" to "seo".
    // So that we can analyze the amount of orders made by users who came from search engines.
    if (!object.afid && isSearchEngine(document.referrer)) object.afid = 'seo';

    // Find marketing params
    for (const name of PARAM_NAMES) {
      const value = object[name];
      if (value) {
        setCookie(name, value);
      }
    }
  }, []);
};
