/**
 *
 * IQ Test
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import { Element, scroller } from 'react-scroll';
import { createStructuredSelector } from 'reselect';
import posthog from 'posthog-js';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { isMobile, isMobileOnly } from 'react-device-detect';
import injectReducer from '../../utils/injectReducer';
import injectSaga from '../../utils/injectSaga';
import reducer from './reducer';
import saga from './saga';
import { selectAnswers, selectCurrentQuestion, selectVocabQuestionType, selectQuestionTypeId, selectQuestionTypeNumber, selectStarted } from './selectors';
import Loading from './Loading';
import StartModal from './StartModal';
import MultipleLoadingModal from './MultipleLoadingModal';
import {
  AnswerHeading,
  AnswerHeadingWrapper,
  AnswerLetter,
  AnswerPic,
  AnswerRow,
  ChooseAnswerWrapper,
  CurrentSectionText,
  CurrentSectionWrapper,
  HorizontalLine,
  QuestionHeading,
  QuestionsWrapper,
  QuestionWrapper,
  ResultLink,
  SkipLink,
} from './style';
import Timer from './Timer';
import QuestionsLinks from './QuestionsLinks';
import {
  calculateResult as calculateResultAction,
  chooseAnswer as chooseAnswerAction,
  finishTest as finishTestAction,
  setTestConfigs as setTestConfigsAction,
  startLoading as startLoadingAction,
  startTest as startTestAction,
  updateQuestion as updateQuestionAction,
} from './actions';
import configs from '../../assets/img/iqtest/tests/vocab/configs';

export class IqTest extends React.PureComponent {
  constructor(props) {
    super(props);
    const { testId, startLoading, setTestConfigs } = props;
    startLoading();
    this.scrollEl = React.createRef();
    this.state = {
      showProgress: false,
      currentSection: 1,
      currentTextForSelection: 'Section 1: Synonym Recognition',
      testId,
      testConfigs: configs,
      error: null,
      errorInfo: null,
      startTimer: false,
      startedTime: null,
      initialLoading: true,
    };

    this.chooseAnswer = this.chooseAnswer.bind(this);
    this.nextQuestion = this.nextQuestion.bind(this);
    this.startTest = this.startTest.bind(this);
    this.onInitialLoadComplete = this.onInitialLoadComplete.bind(this);

    setTestConfigs(configs, testId);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentQuestion !== this.props.currentQuestion) {
      this.setCurrentSectionText();
    }
  }

  onInitialLoadComplete() {
    this.setState({ initialLoading: false });
  }

  setCurrentSectionText() {
    const { currentQuestion } = this.props;
    if (currentQuestion / 4 <= 1)
      return this.setState({
        currentTextForSelection: 'Section 1: Synonym Recognition',
      });

    if (currentQuestion / 8 <= 2)
      return this.setState({
        currentTextForSelection: 'Section 2: Verbal Reasoning Complete',
      });

    if (currentQuestion / 12 <= 3)
      return this.setState({
        currentTextForSelection: 'Section 3: Verbal Intelligence Complete',
      });

    if (currentQuestion / 16 <= 4)
      return this.setState({
        currentTextForSelection: 'Section 4: Antonym Recognition Complete',
      });

    if (currentQuestion / 20 <= 5)
      return this.setState({
        currentTextForSelection: 'Section 5: Pattern Recognition',
      });

    return this.setState({
      currentTextForSelection: 'Section 1: Synonym Recognition',
    });
  }

  scrollToElement() {
    if (isMobileOnly) {
      scroller.scrollTo('myScrollToElement', {
        duration: 500,
        delay: 10,
        smooth: true,
      });
    }
  }

  startTest() {
    posthog.capture('Quiz Started');
    this.setState({ startTimer: true, startedTime: new Date().getTime() });
    this.props.startTest();
    this.scrollToElement();
  }

  showTestProgress() {
    const { questionTypeId } = this.props;
    this.setState({
      showProgress: true,
      currentSection: questionTypeId + 1,
      startTimer: false,
    });
  }

  nextQuestion() {
    const { updateQuestion, currentQuestion, history, finishTest, calculateResult } = this.props;
    const { showProgress, testConfigs, startedTime } = this.state;
    const setNextQuestionOrFinish = () => {
      if (currentQuestion < testConfigs.totalQuestionsNumber) {
        updateQuestion(currentQuestion + 1);
      } else {
        this.setState({ startTimer: false }, () => {
          const totalTime = parseInt((new Date().getTime() - startedTime) / 1000, 10);
          finishTest(totalTime);
          calculateResult(history);
          history.push('/save');
        });
      }
    };

    if (showProgress) {
      return this.setState(
        {
          showProgress: false,
          startTimer: true,
        },
        setNextQuestionOrFinish,
      );
    }

    return setNextQuestionOrFinish();
  }

  chooseAnswer(answer) {
    const { currentQuestion, questionType, questionTypeNumber, updateQuestion, chooseAnswer } = this.props;

    const { testConfigs } = this.state;
    let totalQuestions = 0;
    let needToShowProgress = false;

    Object.values(testConfigs.parts).some(value => {
      if (currentQuestion !== totalQuestions) {
        totalQuestions += value;
        return false;
      }
      needToShowProgress = true;
      return true;
    });

    if (currentQuestion === testConfigs.totalQuestionsNumber) {
      needToShowProgress = true;
    }

    if (needToShowProgress) {
      this.showTestProgress();
    } else {
      updateQuestion(currentQuestion + 1);
    }

    chooseAnswer(questionType, questionTypeNumber, answer);

    this.scrollToElement();
  }

  render() {
    const { currentQuestion, questionType, questionTypeNumber, updateQuestion, started, answers } = this.props;
    const { currentSection, showProgress, testId, testConfigs, currentTextForSelection, error, errorInfo, startTimer, initialLoading } = this.state;

    if (error) {
      return (
        <div>
          <p>Something goes wrong!</p>
          <p>{errorInfo}</p>
        </div>
      );
    }

    if (initialLoading) {
      return (
        <div className="row justify-content-center">
          <div className="col-12 text-left">
            <Loading title="Your Vocab test is being prepared" onInitialLoadComplete={this.onInitialLoadComplete} isVocab />
          </div>
        </div>
      );
    }

    const questionPath = `${testId}/${questionType}/${questionTypeNumber}`;
    const questionShapes = `assets/img/iqtest/tests/${questionPath}q.png`;
    const answerA = `assets/img/iqtest/tests/${questionPath}a.png`;
    const answerB = `assets/img/iqtest/tests/${questionPath}b.png`;
    const answerC = `assets/img/iqtest/tests/${questionPath}c.png`;
    const answerD = `assets/img/iqtest/tests/${questionPath}d.png`;
    return (
      <QuestionsWrapper>
        <StartModal
          isOpen={!initialLoading && !started}
          onPress={this.startTest}
          questionsTimer={testConfigs.timerInMinutes}
          questionNumber={testConfigs.totalQuestionsNumber}
          testType="Vocab"
        />
        <MultipleLoadingModal isVocab isOpen={showProgress} currentSection={currentSection} onPress={this.nextQuestion} />
        <Element name="myScrollToElement">
          <div className="row justify-content-center">
            <CurrentSectionWrapper className="col-md-6">
              <CurrentSectionText>{currentTextForSelection}</CurrentSectionText>
            </CurrentSectionWrapper>
            <div className="col-md-6">
              <Timer start={startTimer} minutes={testConfigs.timerInMinutes} />
            </div>
            <QuestionWrapper className="col-md-6 px-3 py-2 py-md-3 text-left">
              <QuestionHeading>
                Question {currentQuestion}/{testConfigs.totalQuestionsNumber}
              </QuestionHeading>
              <HorizontalLine />
              <img src={questionShapes} className="img-fluid" alt="question" />
            </QuestionWrapper>
            <ChooseAnswerWrapper className="col-md-6 px-3 py-2 py-md-3 text-left">
              <AnswerHeadingWrapper>
                <AnswerHeading className="float-left">Select an answer</AnswerHeading>
                {currentQuestion >= testConfigs.totalQuestionsNumber ? (
                  <ResultLink onClick={this.nextQuestion} className="float-right">
                    Results
                  </ResultLink>
                ) : (
                  <SkipLink onClick={() => updateQuestion(currentQuestion + 1)} className="float-right">
                    Skip
                  </SkipLink>
                )}
              </AnswerHeadingWrapper>
              <HorizontalLine />
              <div className="row">
                <AnswerRow className={`col-6 pb-4 ${isMobile ? 'mobile ' : ''}`} onClick={() => this.chooseAnswer('a')}>
                  <AnswerLetter>A</AnswerLetter>
                  <AnswerPic src={answerA} className="img-fluid" />
                </AnswerRow>
                <AnswerRow className={`col-6 pb-4 ${isMobile ? 'mobile ' : ''}`} onClick={() => this.chooseAnswer('b')}>
                  <AnswerLetter>B</AnswerLetter>
                  <AnswerPic src={answerB} className="img-fluid" />
                </AnswerRow>
                <AnswerRow className={`col-6 pb-4 ${isMobile ? 'mobile ' : ''}`} onClick={() => this.chooseAnswer('c')}>
                  <AnswerLetter>C</AnswerLetter>
                  <AnswerPic src={answerC} className="img-fluid" />
                </AnswerRow>
                <AnswerRow className={`col-6 pb-4 ${isMobile ? 'mobile ' : ''}`} onClick={() => this.chooseAnswer('d')}>
                  <AnswerLetter>D</AnswerLetter>
                  <AnswerPic src={answerD} className="img-fluid" />
                </AnswerRow>
              </div>
            </ChooseAnswerWrapper>
            <div className="col-10 pt-4 pb-3">
              <QuestionsLinks testParts={testConfigs.parts} answers={answers} updateQuestion={updateQuestion} currentQuestion={currentQuestion} />
            </div>
          </div>
        </Element>
      </QuestionsWrapper>
    );
  }
}

IqTest.propTypes = {
  testId: PropTypes.string,
  setTestConfigs: PropTypes.func,
  startTest: PropTypes.func,
  currentQuestion: PropTypes.number,
  started: PropTypes.bool,
  answers: PropTypes.object,
  questionType: PropTypes.string,
  questionTypeNumber: PropTypes.number,
  questionTypeId: PropTypes.number,
  updateQuestion: PropTypes.func,
  chooseAnswer: PropTypes.func,
  finishTest: PropTypes.func,
  calculateResult: PropTypes.func,
  startLoading: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

IqTest.defaultProps = {
  testId: 'vocab',
  questionTypeNumber: 1,
};

const mapStateToProps = createStructuredSelector({
  questionTypeId: selectQuestionTypeId,
  currentQuestion: selectCurrentQuestion,
  questionType: selectVocabQuestionType,
  questionTypeNumber: selectQuestionTypeNumber,
  started: selectStarted,
  answers: selectAnswers,
});

const mapDispatchToProps = dispatch => ({
  setTestConfigs: (configs, id) => dispatch(setTestConfigsAction(configs, id)),
  startTest: () => dispatch(startTestAction()),
  updateQuestion: number => dispatch(updateQuestionAction(number)),
  chooseAnswer: (questionType, questionTypeNumber, answer) => dispatch(chooseAnswerAction(questionType, questionTypeNumber, answer)),
  finishTest: totalTime => dispatch(finishTestAction(totalTime)),
  calculateResult: () => dispatch(calculateResultAction()),
  startLoading: () => dispatch(startLoadingAction()),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'iqTest', reducer });
const withSaga = injectSaga({ key: 'iqTest', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(IqTest);
