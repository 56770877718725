import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { device } from '../../styles/devices';
import { ButtonFilledOrangeLarge } from '../DesignSystem/Buttons';
import ErrorCard from '../ErrorCard';

const Wrapper = styled.div`
  min-height: 100vh;
  position: relative;
  padding-top: 90px;
  padding-bottom: 70px;
`;

const Inner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin: 69px 0;
  text-align: center;

  @media ${device.tablet} {
    margin-top: 126px;
  }
  @media ${device.laptop} {
    margin-top: 140px;
  }
`;

const ErrorIndicator = ({ message }) => {
  const onToHomeNatively = () => {
    window.location.pathname = '/';
  };
  return (
    <Wrapper className="main-content top-background" data-testid="SWW">
      <Inner>
        <div className="container">
          <div className="col col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
            <ErrorCard
              title="We are sorry, but something went wrong"
              subTitle="Our developers will fix this problem"
              description={message}
              buttons={<ButtonFilledOrangeLarge onClick={onToHomeNatively}>Go to home page</ButtonFilledOrangeLarge>}
            />
          </div>
        </div>
      </Inner>
    </Wrapper>
  );
};

ErrorIndicator.propTypes = {
  message: PropTypes.string,
};

export default ErrorIndicator;
