import styled from 'styled-components';

export const QuestionLink = styled.div`
  display: inline-block;
  border: 2px solid ${props => (props.active || props.complete ? '#b4dfec' : '#eee')};
  border-radius: 15px;
  padding: 5px 0;
  margin: 5px;
  font-size: 14px;
  width: 60px;
  background-color: ${props => (props.complete ? '#b4dfec' : 'transparent')};
  cursor: pointer;
`;
