/*
 * LandingResendEmailPage
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import posthog from 'posthog-js';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { historyPropTypes } from 'history-prop-types';
import { withFirebase } from 'react-redux-firebase';
import { getFirestore } from 'redux-firestore';
import injectSaga from '../../utils/injectSaga';
import injectReducer from '../../utils/injectReducer';
import saga from './saga';
import reducer from './reducer';
import { resend as resendAction } from './actions';
import '../../assets/css/style.css';
import '../../assets/css/page.css';

class LandingResendEmailPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
    };
    this.handlerResend = this.handlerResend.bind(this);
  }

  componentDidMount() {
    const {
      history,
      match: {
        params: { token },
      },
    } = this.props;
    getFirestore({})
      .collection(`landingUsers`)
      .doc(token)
      .get()
      .then(doc => {
        if (!doc.exists) {
          posthog.capture('User not found');
          history.push('/start');
          return false;
        }

        const user = doc.data();
        if (!user.subscribed && !user.isSingleSale) {
          posthog.capture('User has no active subscription');
          history.push('/start');
          return false;
        }

        this.setState({ email: user.email });

        return user;
      })
      .catch(({ name, message, stack }) => {
        posthog.capture('Internal error', { name, message, stack });
        history.push('/start');
      });
    document.body.classList.add('layout-centered');
    document.getElementById('app').classList.add('main-content', 'login-bg');
    posthog.capture('Landing Resend Email page opened', {
      referrer: document.referrer,
    });
  }

  componentWillUnmount() {
    document.body.classList.remove('layout-centered');
    document.getElementById('app').classList.remove('main-content', 'login-bg');
  }

  componentWillMount() {
    const { auth, history } = this.props;
    if (!auth.isEmpty) {
      history.push('/');
    }
  }

  handlerResend(event) {
    event.preventDefault();
    posthog.capture('Landing Resend Email button clicked');
    this.props.resend(this.props.match.params.token);
  }

  render() {
    const { processing, success, error } = this.props;
    const { email } = this.state;
    if (!email) {
      return null;
    }

    const getError = () => {
      if (error) {
        return (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        );
      }

      return null;
    };

    const getSuccess = () => {
      if (success) {
        return (
          <div className="alert alert-success" role="alert">
            Email sent.
          </div>
        );
      }

      return null;
    };
    return (
      <div>
        <div className="bg-white rounded shadow-7 w-400 mw-100 p-6">
          <h5 className="mb-6">Just one more step to create your account</h5>
          <p>
            It looks like you have not finished creating your account after purchasing a premium access. You must set a password using a link sent to your email
            address after your purchase. If you have not received such email or can’t find it, you can click “Resend” button below to send it again. Please
            verify that your email address is correct before clicking &quot;Resend&quot;. If the email is incorrect please contact contact@brainable.com.
          </p>
          {getError()}
          {getSuccess()}
          <form className="input-line1" onSubmit={this.handlerResend}>
            <div className="form-group">
              <div className="form-group">
                <input type="email" className="form-control" name="email" value={email} placeholder="Email Address" disabled />
              </div>
              <button className="btn btn-lg btn-block btn-primary" type="submit" disabled={processing}>
                {processing ? 'Processing...' : 'Resend'}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

LandingResendEmailPage.propTypes = {
  processing: PropTypes.bool,
  success: PropTypes.bool,
  error: PropTypes.any,
  resend: PropTypes.func,
  history: PropTypes.shape(historyPropTypes),
  auth: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string.isRequired,
    }),
  }),
};

const mapStateToProps = ({ firebase, landingResendEmail: { processing, success, error } }) => ({
  processing,
  success,
  error,
  auth: firebase.auth,
});

const mapDispatchToProps = dispatch => ({
  resend: id => dispatch(resendAction(id)),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'landingResendEmail', reducer });
const withSaga = injectSaga({ key: 'landingResendEmail', saga });

export default compose(
  withRouter,
  withFirebase,
  withReducer,
  withSaga,
  withConnect,
)(LandingResendEmailPage);
