import React, { useEffect } from 'react';
import styled from 'styled-components';

const AccessibilityWrapper = styled.div`
  border-radius: 50%;
  border-color: black;
  border: 3px solid white;
  background-color: #156ff7;
  display: inline-block;
  width: 60px;
  height: 60px;
  padding: 8px;
  text-align: center;
  margin-bottom: 86px;
  :hover {
    -webkit-filter: brightness(90%);
  }
  @media (max-width: 767.98px) {
    margin: auto;
  }
`;

export const AccessibilityScript = () => {
  useEffect(() => {
    if (window.interdeal) return
    
    window.interdeal = {
      "sitekey": "357e817ec31e61c35bb7623230eadd0b",
      "Position": "Left",
      "Menulang": "EN",
      "domains": {
          "js": "https://cdn.equalweb.com/",
          "acc": "https://access.equalweb.com/"
      },
      "btnStyle": {
          "vPosition": [
              "bottom-left",
              "bottom-left"
          ],
          "scale": [
              "0.5",
              "0.5"
          ],
          "color": {
              "main": "#1876c9",
              "second": "#ffffff"
          },
          "icon": {
              "type": 11,
              "shape": "circle"
          }
      }
    };

    const script = document.createElement("script");
    script.src = "https://cdn.equalweb.com/core/4.6.12/accessibility.js";
    script.integrity =
      "sha512-tbmzeMlPGqU0SEFXwKxqHdaa9SnF4EjTai5LCahOcN9KSTIo9LRscxtii6TZ0MSooxl73IZg2cj6klk8wBm0hg==";
    script.crossOrigin = "anonymous";
    script.setAttribute("data-cfasync", "true");
    document.body.appendChild(script);
    script.addEventListener('load', () => {
      setTimeout(() => {
        try {
          window.interdeal?.a11y?.openMenu()
        } catch (error) { /**/ }
      }, 1000)
    })
  }, [])

  return null
}

const AccessibilityIcon = () => (
  <svg fill="white" width="30px" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 131.3">
    <path d="M71.6,131.3c1,0,2.1-0.3,3.1-0.8c3.9-1.8,5.5-6.2,3.6-10.1c0,0-14.3-32.7-16.9-44.7c-1-4.2-1.6-15.3-1.8-20.5c0-1.8,1-3.4,2.6-3.9l32-9.6c3.9-1,6.2-5.5,5.2-9.4c-1-3.9-5.5-6.2-9.4-5.2c0,0-29.6,9.6-40.3,9.6c-10.4,0-39.8-9.4-39.8-9.4c-3.9-1-8.3,0.8-9.6,4.7c-1.3,4.2,1,8.6,5.2,9.6l32,9.6c1.6,0.5,2.9,2.1,2.6,3.9c-0.3,5.2-0.8,16.4-1.8,20.5c-2.6,12-16.9,44.7-16.9,44.7c-1.8,3.9,0,8.3,3.6,10.1c1,0.5,2.1,0.8,3.1,0.8c2.9,0,5.7-1.6,6.8-4.4l15.3-31.2l14.6,31.4C66.1,129.7,68.7,131.3,71.6,131.3z" />
    <circle stroke="white" cx="50.3" cy="14.6" r="14.6" />
  </svg>
);

const SmallAccessibilityIcon = () => (
  <svg fill="white" width="20px" xmlns="http://www.w3.org/2000/svg" viewBox="-70 -15 240 160">
    <circle stroke="white" cx="50" cy="65" r="120" fill="blue" />
    <path d="M71.6,131.3c1,0,2.1-0.3,3.1-0.8c3.9-1.8,5.5-6.2,3.6-10.1c0,0-14.3-32.7-16.9-44.7c-1-4.2-1.6-15.3-1.8-20.5c0-1.8,1-3.4,2.6-3.9l32-9.6c3.9-1,6.2-5.5,5.2-9.4c-1-3.9-5.5-6.2-9.4-5.2c0,0-29.6,9.6-40.3,9.6c-10.4,0-39.8-9.4-39.8-9.4c-3.9-1-8.3,0.8-9.6,4.7c-1.3,4.2,1,8.6,5.2,9.6l32,9.6c1.6,0.5,2.9,2.1,2.6,3.9c-0.3,5.2-0.8,16.4-1.8,20.5c-2.6,12-16.9,44.7-16.9,44.7c-1.8,3.9,0,8.3,3.6,10.1c1,0.5,2.1,0.8,3.1,0.8c2.9,0,5.7-1.6,6.8-4.4l15.3-31.2l14.6,31.4C66.1,129.7,68.7,131.3,71.6,131.3z" />
    <circle stroke="white" cx="50" cy="65" r="100" strokeWidth="10" fill="none" />
    <circle stroke="white" cx="50.3" cy="14.6" r="14.6" />
  </svg>
);

export { AccessibilityIcon, AccessibilityWrapper, SmallAccessibilityIcon };
