/*
 * TestimonialsPage
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { historyPropTypes } from 'history-prop-types';
import { withFirebase } from 'react-redux-firebase';
import styled from 'styled-components';

import '../../assets/css/style.css';
import '../../assets/css/page.css';

/** Components */
import { Button as HeroSectionButton } from '../../components/HeroSection/HeroSection';
import { withRouter } from 'react-router-dom';
import { LayoutCentered } from '../../components/DesignSystem/Layouts';
import HeroSection from '../../components/HeroSection';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import TestimonialList from './TestimonialList';

/** SVGs */
import TestimonialsDesktop from './Icons/TestimonialsDesktop.svg';
import TestimonialsMainBgDesktop from '../../assets/img/testimonials_page/testimonials-main-bg-v1-desktop.svg';

const Section = styled.section`
  padding-top: 0;
  padding-bottom: 0;
`;

const HeaderText = <>Testimonials</>;
const SubheaderText = <>Look what other people say about Brainable and feel free to ger started!</>;

/* eslint-disable react/prefer-stateless-function */
class TestimonialsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.handleClick = this.handleClick.bind(this);
  }

  componentWillMount() {
    const { auth, history } = this.props;
    if (!auth.isEmpty) {
      history.push('/');
    }
  }

  componentDidUpdate() {
    const { signupResult, history, auth, location } = this.props;
    if (signupResult.isSuccess && !auth.isEmpty) {
      if (location.state && location.state.from) {
        history.push(location.state.from);
      } else {
        history.push('/');
      }
    }
  }

  handleClick() {
    this.props.history.push('/signup');
  }

  render() {
    return (
      <div>
        <Header userEmail={this.props.auth.email} />
        <div className="container">
          <HeroSection
            image={<TestimonialsDesktop />}
            headerText={HeaderText}
            subheaderText={SubheaderText}
            renderButtons={() => <HeroSectionButton onClick={this.handleClick}>Get Started</HeroSectionButton>}
          />
        </div>
        <LayoutCentered backgroundImage={TestimonialsMainBgDesktop}>
          <main>
            <Section className="section">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-md-10 offset-md-1 col-lg-12 offset-lg-0">
                    <TestimonialList />
                  </div>
                </div>
              </div>
            </Section>
          </main>
        </LayoutCentered>
        <Footer />
      </div>
    );
  }
}

TestimonialsPage.propTypes = {
  signupResult: PropTypes.shape({
    isSuccess: PropTypes.bool,
    errorMessage: PropTypes.string,
  }),
  auth: PropTypes.object,
  firebase: PropTypes.shape({
    login: PropTypes.func.isRequired,
  }),
  history: PropTypes.shape(historyPropTypes),
  location: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  signupResult: state.signup.signupResult,
  auth: state.firebase.auth,
});

const withConnect = connect(
  mapStateToProps,
  null,
);

export default compose(
  withRouter,
  withFirebase,
  withConnect,
)(TestimonialsPage);
