/*
 *
 * LandingCheckOutPage actions
 *
 */

export const CHARGE_REQUEST = 'app/QuizCheckOutPage/CHARGE_REQUEST';
export const chargeRequest = ({ token, braintreeNonce, walletToken, paymentType, resultId, campaignType, formData, amount, isTrial }) => ({
  type: CHARGE_REQUEST,
  token,
  braintreeNonce,
  walletToken,
  paymentType,
  resultId,
  campaignType,
  formData,
  amount,
  isTrial,
});

export const CHARGE_REQUEST_SUCCESS = 'app/QuizCheckOutPage/CHARGE_REQUEST_SUCCESS';
export const chargeRequestSuccess = () => ({
  type: CHARGE_REQUEST_SUCCESS,
});

export const PAYMENT_ERROR = 'app/QuizCheckOutPage/PAYMENT_ERROR';
export const checkForErrors = message => ({
  type: PAYMENT_ERROR,
  message,
});

export const START_PROCESSING_PAYMENT = 'app/QuizCheckOutPage/START_PROCESSING_PAYMENT';
export const startProcessingPayment = () => ({
  type: START_PROCESSING_PAYMENT,
});

export const CHANGE_PRICE = 'app/QuizCheckOutPage/CHANGE_PRICE';
export const changePrice = price => ({
  type: CHANGE_PRICE,
  price,
});
