import { all, put, takeLatest, select } from 'redux-saga/effects';
import { CALCULATE_RESULT, setTestResult } from './actions';
import { QUESTION_TYPES } from './constants';

export function* calculateResult() {
  const userAnswers = yield select(state => state.iqTest.answers);
  const { correctAnswers, baseTotalScores, correctAnswerCost } = yield select(state => state.iqTest.testConfigs);

  const scores = {};
  let totalScore = baseTotalScores;
  const categoryDefault = baseTotalScores / QUESTION_TYPES.length;
  Object.entries(userAnswers).forEach(([category, answers]) => {
    if (correctAnswers[category]) {
      scores[category] = categoryDefault;
      Object.entries(answers).forEach(([question, answer]) => {
        const correctAnswer = correctAnswers[category][question];
        if (answer.toLowerCase() === correctAnswer.toLowerCase()) {
          scores[category] += correctAnswerCost;
          totalScore += correctAnswerCost;
        }
      });
    }
  });

  scores.totalScore = totalScore;

  yield put(setTestResult(scores));
}

export default function* defaultSaga() {
  yield all([yield takeLatest(CALCULATE_RESULT, calculateResult)]);
}
