/*
 *
 * Testimonial Data
 *
 */

import { v4 } from 'uuid';
import avatarPlaceholder from '../../assets/img/testimonials_page/avatar-placeholder.svg';

export const testimonialData = [
  {
    id: v4(),
    userName: 'Richard',
    quote: 'This was a difficult test. Really made me work for answers. I was pleased with the results I know my brain is working. Thank you!',
    rating: 7,
    avatar: avatarPlaceholder,
  },
  {
    id: v4(),
    userName: 'Jeremiah Henson',
    quote:
      'Really enjoy Brainable. It pushes your brain. The IQ test was a great experience. If you haven’t tried them out, you should. Awesome games, like it much. Good job!',
    rating: 5,
    avatar: avatarPlaceholder,
  },
  {
    id: v4(),
    userName: 'Judy Lee',
    quote:
      'So much fun to get your IQ done. Nothing complicated. Just simple and completed in no time. I really loved hearing my results, and they are very much aligned with my profession. Nothing complicated. Just simple and completed in no time. I really loved hearing my results. Thank you!',
    rating: 5,
    avatar: avatarPlaceholder,
  },
  {
    id: v4(),
    userName: 'Richard',
    quote: 'This was a difficult test. Really made me work for answers. I was pleased with the results I know my brain is working. Thank you!',
    rating: 5,
    avatar: avatarPlaceholder,
  },
  {
    id: v4(),
    userName: 'Jeremiah Henson',
    quote:
      'Really enjoy Brainable. It pushes your brain. The IQ test was a great experience. If you haven’t tried them out, you should. Awesome games, like it much. Good job!',
    rating: 5,
    avatar: avatarPlaceholder,
  },
  {
    id: v4(),
    userName: 'Richard',
    quote: 'This was a difficult test. Really made me work for answers. I was pleased with the results I know my brain is working. Thank you!',
    rating: 5,
    avatar: avatarPlaceholder,
  },
];
