/*
 *
 * GameProvider constants
 *
 */

export const LOAD_GAME_DATA = 'app/gamePage/LOAD_GAME_DATA';
export const GAME_DATA_LOADED = 'app/gamePage/GAME_DATA_LOADED';
export const GAME_DATA_LOAD_ERROR = 'app/gamePage/GAME_DATA_LOAD_ERROR';

export const SAVE_GAME_RESULT = 'app/gamePage/SAVE_GAME_RESULT';
export const SAVE_GAME_RESULT_ERROR = 'app/gamePage/SAVE_GAME_RESULT_ERROR';
export const GAME_RESULT_SAVED = 'app/gamePage/SAVE_GAME_RESULT';
