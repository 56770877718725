import React, { useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import styled from 'styled-components';
import useAxios from 'axios-hooks';
import firebase from '../../config/firebase';
import Header from '../../components/Header';
import Spinner from '../../assets/img/spinner.gif';

const Content = styled.div`
  margin: 100px auto;
  z-index: 15;
  position: relative;
  text-align: center;
  background: white;
  padding: 30px;
  border-radius: 30px;
  max-width: 400px;
  width: 400px;
`;
const DangerColor = styled.span`
  color: var(--danger);
`;
export default () => {
  const history = useHistory();
  const searchParams = new URLSearchParams(window.location.search);
  const email = searchParams.get('email');
  const authKey = searchParams.get('authKey');
  const [{ data, loading, error }] = useAxios({
    url: '/api/user/get-auth-token-by-email-and-key',
    method: 'POST',
    data: {
      email,
      authKey,
    },
  });

  useEffect(
    () => {
      if (data) {
        const { token } = data;
        firebase
          .auth()
          .signInWithCustomToken(token)
          .then(() => {
            history.push('/');
          })
          .catch(e => console.log(e));
      }
    },
    [data],
  );

  if (loading)
    return (
      <>
        <Header />
        <Content>
          <p>
            Loading... <img src={Spinner} alt="" width="30px" height="30px" />
          </p>
        </Content>
      </>
    );
  if (error)
    return (
      <>
        <Header />
        <Content>
          <p>
            <DangerColor>Something wrong happened.</DangerColor> Try to <Link to="/login">log in</Link> using your email and password
          </p>
        </Content>
      </>
    );

  return (
    <>
      <Header />
      <Content>Securely logging you in</Content>
    </>
  );
};
