import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg width="616" height="616" viewBox="0 0 616 616" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="308" cy="308" r="308" fill="#DFEAF4" />
      <circle cx="308" cy="308" r="308" fill="url(#paint0_linear)" />
      <path d="M165.194 142.964L95.4063 170.776L85.2177 204.02L195.234 160.176L165.194 142.964Z" fill="#C8E8FE" />
      <path d="M163.472 240.42L85.2216 204.02L140.23 182.098L195.238 160.176L163.472 240.42Z" fill="white" />
      <path
        d="M521.175 308.141C525.839 290.734 543.73 280.404 561.137 285.068C578.543 289.732 588.873 307.624 584.209 325.031C581.421 335.435 572.915 342.438 563.904 346.782C559.977 348.324 557.294 353.498 556.115 356.112C555.462 357.561 553.792 358.747 552.257 358.335L532.194 352.959C530.659 352.548 529.805 350.686 529.964 349.105C530.25 346.252 530.513 340.43 527.884 337.131C522.252 328.863 518.387 318.545 521.175 308.141Z"
        fill="white"
      />
      <path
        d="M554.089 359.862C554.232 359.329 553.915 358.78 553.382 358.637L531.07 352.659C530.536 352.516 529.988 352.833 529.845 353.366L527.299 362.868C527.217 363.173 527.284 363.498 527.479 363.746L533.627 371.558C533.76 371.728 533.945 371.849 534.154 371.905L541.087 373.763C541.296 373.819 541.517 373.806 541.718 373.726L550.948 370.034C551.241 369.917 551.461 369.669 551.543 369.364L554.089 359.862Z"
        fill="#C8E8FE"
      />
      <path
        d="M511.731 144.687C512.375 144.083 513.429 144.55 513.414 145.433L513.265 154.634C513.259 155.001 513.454 155.341 513.773 155.521L521.667 159.981C522.423 160.409 522.313 161.531 521.488 161.802L512.746 164.676C512.405 164.788 512.151 165.074 512.079 165.425L510.209 174.52C510.032 175.379 508.913 175.611 508.41 174.891L503.213 167.46C503 167.155 502.636 166.994 502.267 167.041L493.271 168.184C492.401 168.294 491.82 167.31 492.338 166.601L497.819 159.107C498.031 158.817 498.071 158.437 497.925 158.109L494.182 149.703C493.828 148.91 494.585 148.074 495.41 148.347L504.017 151.198C504.365 151.314 504.748 151.229 505.015 150.979L511.731 144.687Z"
        fill="white"
      />
      <circle r="29.5" transform="matrix(-0.94642 -0.322937 -0.322937 0.94642 424.43 117.428)" stroke="white" strokeWidth="11" />
      <circle opacity="0.5" r="15" transform="matrix(-0.94642 -0.322937 -0.322937 0.94642 54.3882 272.384)" stroke="#F6813F" strokeWidth="10" />
      <circle cx="112.64" cy="345.728" r="37.4738" transform="rotate(42.7573 112.64 345.728)" stroke="#A3CCE9" strokeWidth="11" />
      <circle cx="149.234" cy="382.321" r="19.5564" transform="rotate(42.7573 149.234 382.321)" stroke="white" strokeWidth="10" />
      <circle r="5" transform="matrix(-0.734236 0.678895 0.678895 0.734236 180.478 105.128)" stroke="white" strokeWidth="10" />
      <circle r="18.558" transform="matrix(-1 0 0 1 439.559 154.558)" stroke="#C8E8FE" strokeWidth="10" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M251 145C237.745 145 227 155.745 227 169V223.683C227 236.938 237.745 247.683 251 247.683H252.048L264.871 257.446L277.693 247.683H386C399.255 247.683 410 236.938 410 223.683V169C410 155.745 399.255 145 386 145H251Z"
        fill="#F4BC9D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M148 284C134.745 284 124 294.745 124 308V462C124 475.255 134.745 486 148 486H173.275L198.5 505.205L223.725 486H460C473.255 486 484 475.255 484 462V308C484 294.745 473.255 284 460 284H148Z"
        fill="#C6DEF1"
      />
      <circle cx="461.5" cy="302.5" r="55.5" fill="#F6813F" />
      <path
        d="M461.5 277L468.994 292.185L485.752 294.62L473.626 306.44L476.489 323.13L461.5 315.25L446.511 323.13L449.374 306.44L437.248 294.62L454.006 292.185L461.5 277Z"
        fill="white"
      />
      <rect x="157" y="338" width="206" height="12" rx="6" fill="white" />
      <rect x="253" y="167" width="88" height="10" rx="5" fill="#FCE0CF" />
      <rect x="253" y="191" width="131" height="10" rx="5" fill="#FCE0CF" />
      <rect x="253" y="215" width="131" height="10" rx="5" fill="#FCE0CF" />
      <rect x="157" y="368" width="206" height="12" rx="6" fill="white" />
      <rect x="157" y="398" width="289" height="12" rx="6" fill="white" />
      <rect x="157" y="428" width="289" height="12" rx="6" fill="white" />
      <defs>
        <linearGradient id="paint0_linear" x1="-228" y1="-583.5" x2="565" y2="1582" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgComponent;
