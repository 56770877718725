/*
 *
 * SignOutProvider reducer
 *
 */

import { SIGNOUT_SUCCESS, SIGNOUT_FILED } from './constants';

export const initialState = {
  signoutResult: {
    isSuccess: false,
    errorMessage: '',
  },
};

function SignoutProviderReducer(state = initialState, action) {
  switch (action.type) {
    case SIGNOUT_SUCCESS:
      return {
        ...state,
        signoutResult: { isSuccess: true, errorMessage: '' },
      };
    case SIGNOUT_FILED:
      return {
        ...state,
        signoutResult: {
          isSuccess: false,
          errorMessage: action.payload,
        },
      };
    default:
      return state;
  }
}

export default SignoutProviderReducer;
