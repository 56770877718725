/*
 *
 * LandingResendEmailPage reducer
 *
 */
import { LANDING_RESEND_EMAIL_FAIL, LANDING_RESEND_EMAIL_PROCESSING, LANDING_RESEND_EMAIL_SUCCESS } from './constants';

export const initialState = {
  processing: false,
  success: false,
  error: false,
};

function LandingResendEmailPageReducer(state = initialState, action) {
  switch (action.type) {
    case LANDING_RESEND_EMAIL_PROCESSING:
      return {
        ...state,
        processing: true,
        success: false,
        error: false,
      };
    case LANDING_RESEND_EMAIL_SUCCESS:
      return {
        ...state,
        processing: false,
        success: true,
        error: false,
      };
    case LANDING_RESEND_EMAIL_FAIL:
      return {
        ...state,
        processing: false,
        success: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}

export default LandingResendEmailPageReducer;
