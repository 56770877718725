import { TRAINING_CALENDAR_DATA_LOADED, TRAINING_CALENDAR_DATA_LOAD_ERROR } from './constants';

export const initialState = {
  trainingCalendar: {
    data: [],

    loadResult: {
      isSuccess: false,
      message: '',
    },
  },
};

function BrainProfileProviderReducer(state = initialState, action) {
  switch (action.type) {
    case TRAINING_CALENDAR_DATA_LOADED:
      return {
        ...state,
        trainingCalendar: {
          data: action.payload,
          loadResult: { isSuccess: true, message: '' },
        },
      };
    case TRAINING_CALENDAR_DATA_LOAD_ERROR:
      return {
        ...state,
        trainingCalendar: {
          data: state.trainingCalendar.data,
          loadResult: {
            isSuccess: false,
            message: 'Can not load trainingCalendar data',
          },
        },
      };

    default:
      return state;
  }
}

export default BrainProfileProviderReducer;
