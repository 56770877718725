export const breakpoint = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 576,
  tablet: 768,
  laptop: 992,
  laptopL: 1200,
  desktop: 1440,
};

export const device = {
  mobileS: `(min-width: ${breakpoint.mobileS}px)`,
  mobileM: `(min-width: ${breakpoint.mobileM}px)`,
  mobileL: `(min-width: ${breakpoint.mobileL}px)`,
  tablet: `(min-width: ${breakpoint.tablet}px)`,
  laptop: `(min-width: ${breakpoint.laptop}px)`,
  laptopL: `(min-width: ${breakpoint.laptopL}px)`,
  desktop: `(min-width: ${breakpoint.desktop}px)`,
};

export const deviceMax = {
  mobileS: `(max-width: ${breakpoint.mobileS - 1}px)`,
  mobileM: `(max-width: ${breakpoint.mobileM - 1}px)`,
  mobileL: `(max-width: ${breakpoint.mobileL - 1}px)`,
  tablet: `(max-width: ${breakpoint.tablet - 1}px)`,
  laptop: `(max-width: ${breakpoint.laptop - 1}px)`,
  laptopL: `(max-width: ${breakpoint.laptopL - 1}px)`,
  desktop: `(max-width: ${breakpoint.desktop - 1}px)`,
};
