import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { SmallAccessibilityIcon, AccessibilityScript } from './Accessibility';

const Wrapper = styled.footer`
  background: #f2f5f7 !important;

  span {
    color: #1f2e44 !important;
  }
  a {
    color: #1f2e44 !important;
  }
  .uppercase {
    a {
      text-transform: uppercase !important;
    }
    span {
      text-transform: uppercase !important;
    }
  }
  .text-overflow {
    span {
      white-space: nowrap;
    }
  }
`;
const BareButton = styled.div`
  border: none;
  background: none;
  & > span {
    margin-left: 5px;
  }
`;

// eslint-disable-next-line react/prop-types
export default ({ copyrightText }) => {
  const [shouldLoadAccessibility, setShouldLoadAccessibility] = useState(false);
  const handleA11yClick = () => {
    if (window.interdeal) {
      window.interdeal?.a11y?.openMenu()
    } else {
      setShouldLoadAccessibility(true)
    }
  }
  return (
  <Wrapper className="footer">
    <div className="container">
      <div className="row gap-y align-items-center">
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-3 order-xl-first order-last text-overflow">
          <div className="nav nav-trim copyright align-items-center justify-content-md-start justify-content-center">
            <span>
              Copyright &copy;
              {copyrightText}
            </span>
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-xl-9 px-0 uppercase">
          <div className="nav nav-trim nav-uppercase justify-content-md-end justify-content-center">
            {shouldLoadAccessibility && <AccessibilityScript />}
            <BareButton className="nav-link pl-4 pl-md-0" onClick={handleA11yClick} style={{ display: 'flex' }}>
              <SmallAccessibilityIcon />
              <span>Accessibility</span>
            </BareButton>
            <Link to="/support-center" className="nav-link">
              Support Center
            </Link>
            <Link to="/terms-of-use" className="nav-link">
              Terms of Use
            </Link>
            <Link to="/privacy" className="nav-link">
              Privacy policy
            </Link>
            <Link to="/careers" className="nav-link">
              Careers
            </Link>
            <Link to="/contact" className="nav-link pr-4 pr-md-0">
              Contact Us
            </Link>
          </div>
        </div>
      </div>
    </div>
  </Wrapper>
)};
