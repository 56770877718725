/**
 *
 * MultipleLoading
 *
 */

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Loading from '../Loading';

const Heading = styled.h3`
  @media (max-width: 767.98px) {
    font-size: 19px;
  }
`;

function MultipleLoading({ currentSection, onCategoryLoadComplete }) {
  return (
    <div>
      <Heading className="noselect">YOUR IQ TEST PROGRESS</Heading>

      <Loading
        title="Section 1: Logical Reasoning Completed"
        titleStyle={2}
        categoryLoaded={currentSection > 1}
        onCategoryLoadComplete={onCategoryLoadComplete}
      />
      {currentSection < 2 ? null : (
        <Loading
          title="Section 2: Mathematical Reasoning Completed"
          titleStyle={2}
          categoryLoaded={currentSection > 2}
          onCategoryLoadComplete={onCategoryLoadComplete}
        />
      )}
      {currentSection < 3 ? null : (
        <Loading
          title="Section 3: Pattern Intelligence Completed"
          titleStyle={2}
          categoryLoaded={currentSection > 3}
          onCategoryLoadComplete={onCategoryLoadComplete}
        />
      )}
      {currentSection < 4 ? null : (
        <Loading
          title="Section 4: Spatial Recognition Completed"
          titleStyle={2}
          categoryLoaded={currentSection > 4}
          onCategoryLoadComplete={onCategoryLoadComplete}
        />
      )}
      {currentSection < 5 ? null : (
        <Loading title="Section 5: Verbal Intelligence" titleStyle={2} categoryLoaded={currentSection > 5} onCategoryLoadComplete={onCategoryLoadComplete} />
      )}
    </div>
  );
}

MultipleLoading.propTypes = {
  currentSection: PropTypes.number.isRequired,
  onCategoryLoadComplete: PropTypes.func,
};

export default MultipleLoading;
