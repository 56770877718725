import styled from 'styled-components';

export const SecureHeader = styled.h3`
  font-size: 20px;
  font-weight: 300 !important;
  position: relative;
  z-index: 10;
  margin-bottom: 20px;
  @media (max-width: 767.98px) {
    font-size: 16px;
    letter-spacing: 0;
    margin-bottom: 10px;
  }
`;
export const SecureImg = styled.img`
  @media (max-width: 767.98px) {
    max-width: 32px;
  }
`;
export const QuizWrapper = styled.div`
  position: relative;
  z-index: 50;
  margin-bottom: 50px;
  display: inline-block;
  background-color: #fff;
  box-sizing: border-box;
  text-align: center;
  padding: 30px 20px 20px;
  @media (max-width: 767.98px) {
    padding-top: 10px;
  }
`;
