/* eslint-disable func-names */
/*
 * LandingQuizPage
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import posthog from 'posthog-js';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import IqTest from '../../components/IqTest/vocab';
import { QuizWrapper, SecureHeader, SecureImg } from './styledComponents';
import securedImg from '../../assets/img/questions_game/secured_icon.png';

/** Components */
import { Main } from '../../components/DesignSystem/Layouts';

class LandingQuizPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      IqTestID: 'vocab',
    };
  }

  componentDidMount() {
    window.dataLayer.push({ event: 'optimize.activate' });
    document.body.classList.add('landing');
    posthog.capture('Landing Quiz page opened', {
      referrer: document.referrer,
    });
    setTimeout(() => {
      (function(document, window) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://api.pushnami.com/scripts/v1/pushnami-adv/5f4eb59431c2b400122523ec';
        script.onload = function() {
          window.Pushnami.update().prompt();
        };
        document.getElementsByTagName('head')[0].appendChild(script);
      })(document, window);
    }, 10000);
  }

  componentWillUnmount() {
    document.body.classList.remove('landing');
  }

  render() {
    return (
      <div className="main-content">
        <Header landing />

        <Main className="main-section GreyBackground">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center mt-9">
                <SecureHeader>
                  <SecureImg src={securedImg} alt="secure" />
                  &nbsp; Your connection is 100% Secure
                </SecureHeader>
                <div className="row justify-content-center">
                  <QuizWrapper className="col-11 col-md-10 card shadow-4">
                    <IqTest testId={this.state.IqTestID} history={this.props.history} />
                  </QuizWrapper>
                </div>
              </div>
            </div>
          </div>
        </Main>

        <Footer />
      </div>
    );
  }
}

LandingQuizPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default LandingQuizPage;
