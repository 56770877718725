import styled from 'styled-components';
import { MEDIA_QUERIES } from '../../../utils/constants';

const FooterWrapper = styled.footer`
  background: #1f2d44;
  position: relative;
  padding: 24px 0;
`;

const BareButton = styled.div`
  display: flex;
  border: none;
  background: none;
  & > span {
    margin-left: 15px;
  }
`;

const AccessibilityCol = styled.div`
  @media (min-width: ${MEDIA_QUERIES.desktop.min}) {
    display: none;
  }
`;

const CopyrightCol = styled.div`
  @media (min-width: ${MEDIA_QUERIES.desktop.min}) {
    flex: 0 0 100%;
    max-width: 100%;
  }

  @media (min-width: ${MEDIA_QUERIES.wideScreen.min}) {
    flex: 0 0 30%;
    max-width: 30%;
    order: -1;
  }

  .copyright {
    font-size: 14px;
    line-height: 21px;
    justify-content: flex-end;
    @media (min-width: ${MEDIA_QUERIES.desktop.min}) {
      justify-content: flex-start;
    }
  }
`;

const NavCol = styled.div`
  margin-bottom: 20px;

  @media (min-width: ${MEDIA_QUERIES.wideScreen.min}) {
    flex: 0 0 70%;
    max-width: 70%;
    margin-bottom: 0;
  }

  .nav {
    justify-content: space-between;

    @media (min-width: ${MEDIA_QUERIES.wideScreen.min}) {
      justify-content: flex-end;
    }
  }

  .nav-link:nth-child(2) {
    padding-left: 0;
    @media (min-width: ${MEDIA_QUERIES.desktop.min}) {
      padding-left: 15px;
    }
  }

  ${BareButton} {
    display: none;

    @media (min-width: ${MEDIA_QUERIES.desktop.min}) {
      display: flex;
    }
  }
`;

const FooterDesktop = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  .nav-link {
    font-size: 16px;
    line-height: 21px;
    padding: 10px 15px;
  }
`;

const FooterMobile = styled.div`
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
  color: white;
  margin: auto;

  .nav-link {
    padding: 10px 0;
    width: 100%;
  }

  .copyright {
    margin-top: 15px;
  }

  > [class*='col-'] {
    @media (max-width: ${MEDIA_QUERIES.mobile.max}) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
`;

const LinksBlock = styled.div`
  margin-left: auto;
  align-items: center;
  display: flex;
  flex-direction: column;
  .nav {
    margin-top: 22px;
  }

  @media (max-width: 767.98px) {
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: end;
    margin-top: 66px;
  }
`;

const IconsContainer = styled.div`
  margin-right: 50px;
`;

const IconBox = styled.div`
  width: 26px;
  height: 26px;
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }

  svg {
    width: 100%;
    height: auto;
  }
`;

const IconRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export { FooterWrapper, BareButton, LinksBlock, FooterDesktop, FooterMobile, AccessibilityCol, CopyrightCol, NavCol, IconBox, IconRow, IconsContainer };
