export default {
  type: 'vocab',
  totalQuestionsNumber: 20,
  timerInMinutes: 20,
  parts: {
    synonym: 4,
    reasoning: 4,
    intelligence: 4,
    antonym: 4,
    pattern: 4,
  },
  baseTotalScores: 80,
  correctAnswerCost: 2,
  correctAnswers: {
    synonym: { '1': 'C', '2': 'C', '3': 'A', '4': 'A' },
    reasoning: { '1': 'D', '2': 'A', '3': 'D', '4': 'C' },
    intelligence: { '1': 'A', '2': 'C', '3': 'C', '4': 'D' },
    antonym: { '1': 'D', '2': 'D', '3': 'D', '4': 'B' },
    pattern: { '1': 'C', '2': 'A', '3': 'B', '4': 'C' },
  },
};
