import styled from 'styled-components';

/** Constants */
import palette from '../../styles/palette';

const H0 = styled.h1`
  font-family: SF Pro Rounded;
  font-style: normal;
  font-weight: 800;
  font-size: 65px;
  line-height: 65px;
  color: ${palette.blackBlue};
  @media (max-width: 1200px) {
    font-size: 50px;
    line-height: 55px;
  }
  @media (max-width: 767.98px) {
    font-size: 38px;
    line-height: 43px;
    text-align: center;
  }
`;

const H1 = styled(H0)`
  font-weight: 700;
  font-size: 55px;
`;

const H2 = styled.h2`
  font-family: SF Pro Rounded;
  font-size: 46px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 0.2px;
  color: ${palette.blackBlue};
  @media (max-width: 1200px) {
    font-size: 40px;
    line-height: 46px;
  }
  @media (max-width: 1023px) {
    font-weight: 600;
    font-size: 38px;
    line-height: 44px;
  }
  @media (max-width: 767.98px) {
    font-size: 36px;
    line-height: 42px;
  }
`;

const H3 = styled.h3`
  font-family: SF Pro Rounded;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.2px;
  @media (max-width: 764px) {
    font-size: 27px;
    line-height: 34px;
  }
`;

const H4 = styled.h4`
  font-family: SF Pro Rounded;
  font-style: normal;
  line-height: 65px;
  color: ${palette.blackBlue};
  font-size: 26px;
  font-weight: 500;
  line-height: 30px;

  @media (max-width: 1200px) {
    font-size: 24px;
    line-height: 30px;
  }

  @media (max-width: 764px) {
    font-size: 20px;
    line-height: 26px;
    text-align: center;
  }
`;

export { H0, H1, H2, H3, H4 };
