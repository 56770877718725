/*
 *
 * IqTest reducer
 *
 */

import { CHOOSE_ANSWER, FINISH_TEST, SET_TEST_CONFIGS, SET_TEST_RESULT, START_TEST, UPDATE_QUESTION, START_LOADING, FINISH_PERSONALITY_TEST } from './actions';

export const initialState = {
  currentQuestion: 1,
  scores: {},
  answers: {
    logical: {},
    mathematical: {},
    pattern: {},
    spatial: {},
    verbal: {},
    synonym: {},
    reasoning: {},
    intelligence: {},
    antonym: {},
    // pattern: {},
  },
  totalTime: null,
  testId: null,
  testConfigs: null,
};

function iqTestReducer(state = initialState, action) {
  switch (action.type) {
    case SET_TEST_CONFIGS:
      return {
        ...state,
        testConfigs: action.configs,
        testId: action.testId,
      };
    case START_LOADING:
      return initialState;
    case START_TEST:
      return {
        ...state,
        started: true,
      };
    case UPDATE_QUESTION:
      return {
        ...state,
        currentQuestion: action.number,
      };
    case CHOOSE_ANSWER:
      return {
        ...state,
        answers: {
          ...state.answers,
          [action.questionType]: {
            ...state.answers[action.questionType],
            [action.questionTypeNumber]: action.answer,
          },
        },
      };
    case FINISH_TEST:
      return {
        ...state,
        totalTime: action.totalTime,
      };
    case SET_TEST_RESULT:
      return {
        ...state,
        scores: action.scores,
      };
    case FINISH_PERSONALITY_TEST:
      return {
        ...state,
        testId: 'personality',
        scores: action.results,
        totalTime: action.totalTime,
      };
    default:
      return state;
  }
}

export default iqTestReducer;
