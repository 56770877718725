/**
 *
 * Timer
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const TimerBlock = styled.div`
  border: 2px solid #55afcb;
  border-radius: 35px;
  width: 70px;
  height: 70px;
  float: right;
  margin: 10px 20px;
  line-height: 65px;
  font-size: 19px;
  color: #55afcb;
  text-align: center;
  @media (max-width: 767.98px) {
    width: auto;
    margin: 0;
    border: 0;
    height: 74px;
    line-height: 54px;
  }
`;

class Timer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { minutes: props.minutes, seconds: '00' };
    this.timer = null;
    this.startTimer = this.startTimer.bind(this);
  }

  componentWillMount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  componentDidUpdate(prevProps) {
    const { start } = this.props;
    if (prevProps.start !== start && start === true) {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.timer = setInterval(this.startTimer, 1000);
    }

    if (prevProps.start !== start && start === false) {
      if (this.timer) {
        clearInterval(this.timer);
      }
    }
  }

  startTimer() {
    const { seconds, minutes } = this.state;

    if (parseInt(seconds, 10) === 0) {
      const trueMin = minutes === '00' ? -0 : minutes - 1;
      this.setState({
        seconds: 59,
        minutes: trueMin.toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }),
      });
    } else {
      this.setState({
        seconds: (seconds - 1).toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }),
      });
    }
  }

  render() {
    const { minutes, seconds } = this.state;
    return (
      <TimerBlock>
        {minutes}:{seconds}
      </TimerBlock>
    );
  }
}

Timer.propTypes = {
  start: PropTypes.bool.isRequired,
  minutes: PropTypes.number.isRequired,
};

export default Timer;
