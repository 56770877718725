import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { v4 } from 'uuid';

/** SVGs */
import RatingStar from './Icons/RatingStar.svg';

/** Constants */
import palette from '../../styles/palette';
import { device } from '../../styles/constants';

/**
 *
 * Rating
 *
 */

const RatingWrapper = styled.div`
  display: flex;
  margin-top: 7px;
`;

const Rating = ({ rating, className }) => {
  let ratingVar = rating;
  if (rating > 5) ratingVar = 5;
  if (rating < 0) ratingVar = 0;
  const positives = useMemo(() => [...Array(ratingVar)].map(() => v4()));
  const negatives = useMemo(() => [...Array(5 - ratingVar)].map(() => v4()));

  return (
    <RatingWrapper className={className}>
      {positives.map(key => (
        <RatingStar key={key} fillColor={palette.ratingSuccess} />
      ))}
      {negatives.map(key => (
        <RatingStar key={key} fillColor={palette.ratingFailed} />
      ))}
    </RatingWrapper>
  );
};

Rating.propTypes = {
  rating: PropTypes.number.isRequired,
  className: PropTypes.string,
};

/**
 *
 * TestimonialItem
 *
 */

const Wrapper = styled.div`
  font-family: Poppins;
  font-style: normal;
  color: ${palette.blackBlue};
  &:not(:first-of-type) {
    margin-top: 40px;
  }
`;

const QuoteWrapper = styled.div`
  position: relative;
  padding-bottom: 30px;

  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  @media ${device.laptop} {
    font-size: 18px;
    line-height: 27px;
  }
`;

const QuoteField = styled.div`
  background-color: #ffffff;
  box-shadow: 0px 100px 80px rgba(134, 156, 174, 0.07), 0px 30.1471px 24.1177px rgba(134, 156, 174, 0.0456112),
    0px 12.5216px 10.0172px rgba(134, 156, 174, 0.035), 0px 4.5288px 3.62304px rgba(134, 156, 174, 0.0243888);
  position: relative;
  border: 1px solid rgba(199, 224, 242, 0.37);
  padding: 37px 38px 30px 38px;
  border-radius: 40px;

  &:after {
    content: '';
    position: absolute;
    height: 0;
    width: 0;
    bottom: -20px;
    left: 50px;
    border-width: 20px 30px 0;
    border-style: solid;
    border-color: #ffffff transparent transparent;
  }
`;

const ItemFooter = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;

  display: flex;

  margin-left: 30px;
  margin-top: 10px;

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const ImgWrapper = styled.div`
  width: 97px;
  height: 97px;
  margin-right: 17px;
`;

const TestimonialItem = ({ userName, quote, avatar, rating }) => {
  const getText = () => {
    if (!Array.isArray(quote)) return quote;
    return quote.map((item, idx) => <span className={idx === quote.length - 1 ? 'd-lg-none' : ''}>{item}</span>);
  };
  const quoteText = useMemo(() => getText());

  return (
    <Wrapper className="testimonial-item">
      <QuoteWrapper>
        <QuoteField>{quoteText}</QuoteField>
      </QuoteWrapper>

      <ItemFooter>
        <ImgWrapper>
          <img src={avatar} loading="lazy" alt="avatar" />
        </ImgWrapper>
        <div>
          <div>{userName}</div>

          <Rating rating={rating} />
        </div>
      </ItemFooter>
    </Wrapper>
  );
};

TestimonialItem.propTypes = {
  userName: PropTypes.string.isRequired,
  rating: PropTypes.number.isRequired,
  avatar: PropTypes.oneOfType([PropTypes.element, PropTypes.node]).isRequired,
  quote: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.string]).isRequired,
};

export default TestimonialItem;
