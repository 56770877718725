/**
 *
 * MultipleLoadingModal
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Modal, ModalBody } from 'reactstrap';
import MultipleLoading from '../MultipleLoading';
import MultipleVocabLoading from '../MultipleLoading/vocab';
import MultipleLoadingPersonality from '../MultipleLoading/personality';
import StaticButton from '../../StaticButton';

const ModalBodyStyled = styled(ModalBody)`
  padding: 10px 20px;
  @media (min-width: 361px) {
    padding: 20px;
  }
  @media (min-width: 768px) {
    padding: 20px 40px 50px 40px;
  }
  @media (max-width: 767.98px) {
    & .progress {
      height: 10px;
    }
  }
  @media (max-width: 361px) {
    & .progress {
      height: 8px;
    }
  }
`;

class MultipleLoadingModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      categoryLoaded: false,
    };

    this.onCategoryLoadComplete = this.onCategoryLoadComplete.bind(this);
    this.onPress = this.onPress.bind(this);
  }

  onPress() {
    this.setState({ categoryLoaded: false });
    this.props.onPress();
  }

  onCategoryLoadComplete() {
    this.setState({ categoryLoaded: true });
  }

  render() {
    const { isOpen, currentSection, isVocab, isPersonality, sectionCount } = this.props;
    const { categoryLoaded } = this.state;
    let MultipleLoadingComponent;
    if (isVocab) {
      MultipleLoadingComponent = MultipleVocabLoading;
    } else if (isPersonality) {
      MultipleLoadingComponent = MultipleLoadingPersonality;
    } else {
      MultipleLoadingComponent = MultipleLoading;
    }
    return (
      <Modal contentClassName="mt-6 align-self-start" isOpen={isOpen} size="lg">
        <ModalBodyStyled>
          <MultipleLoadingComponent currentSection={currentSection} onCategoryLoadComplete={this.onCategoryLoadComplete} />
          <div className="text-center">
            {!categoryLoaded ? null : (
              <StaticButton className="noselect" onClick={this.onPress}>
                {currentSection < sectionCount ? 'Continue' : 'Submit My Answers'}
              </StaticButton>
            )}
          </div>
        </ModalBodyStyled>
      </Modal>
    );
  }
}

MultipleLoadingModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  currentSection: PropTypes.number.isRequired,
  onPress: PropTypes.func.isRequired,
  isVocab: PropTypes.bool,
  isPersonality: PropTypes.bool,
  sectionCount: PropTypes.number,
};

MultipleLoadingModal.defaultProps = {
  isVocab: false,
  isPersonality: false,
  sectionCount: 5,
};

export default MultipleLoadingModal;
