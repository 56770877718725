import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { ButtonFilledOrangeLarge } from '../DesignSystem/Buttons';
import { H2, H4 } from '../DesignSystem/Headers';
import palette from '../../styles/palette';
import { device } from '../../styles/devices';

const Card = styled.div`
  @media ${device.laptop} {
    padding: 75px 152px 80px;
    background: #ffffff;
    border: 1px solid rgba(199, 224, 242, 0.37);
    box-sizing: border-box;
    box-shadow: 0px 100px 80px rgba(134, 156, 174, 0.07), 0px 30.1471px 24.1177px rgba(134, 156, 174, 0.0456112),
      0px 12.5216px 10.0172px rgba(134, 156, 174, 0.035), 0px 4.5288px 3.62304px rgba(134, 156, 174, 0.0243888);
    border-radius: 40px;
    max-width: unset;
  }
`;

const CardBody = styled.div`
  max-width: 360px;
  margin: 0 auto;
`;
const Title = styled(H2)`
  color: ${palette.statusError};
  margin-bottom: 30px;
`;
const SubTitle = styled(H4)`
  color: ${palette.statusError};
  margin-bottom: 60px;
`;
const Description = styled.p`
  margin-bottom: 60px;
`;

export default function ErrorCard({ title, subTitle, description, buttons }) {
  const history = useHistory();

  const onBack = () => {
    history.goBack();
  };

  return (
    <Card>
      <CardBody>
        <Title>{title}</Title>
        <SubTitle>{subTitle}</SubTitle>
        <Description>{description}</Description>
        {!buttons ? <ButtonFilledOrangeLarge onClick={onBack}>Go Back</ButtonFilledOrangeLarge> : buttons}
      </CardBody>
    </Card>
  );
}

ErrorCard.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  description: PropTypes.string,
  buttons: PropTypes.element,
};
