/*
 *
 * DashboardProvider reducer
 *
 */

import { WORKOUT_SESSION_DATA_LOADED, WORKOUT_SESSION_DATA_LOAD_ERROR, TRAINING_CALENDAR_DATA_LOADED, TRAINING_CALENDAR_DATA_LOAD_ERROR } from './constants';

export const initialState = {
  BQ: {
    data: {
      total: {
        value: 0,
        percentage: 0,
      },
      categories: [
        {
          name: 'Memory',
          value: 0,
          percentage: 0,
        },
        {
          name: 'Speed',
          value: 0,
          percentage: 0,
        },
        {
          name: 'Reaction',
          value: 0,
          percentage: 0,
        },
        {
          name: 'Concentration',
          value: 0,
          percentage: 0,
        },
        {
          name: 'Logic',
          value: 0,
          percentage: 0,
        },
      ],
    },

    loadResult: {
      isSuccess: false,
      message: '',
    },
  },
  trainingCalendar: {
    data: [],

    loadResult: {
      isSuccess: false,
      message: '',
    },
  },
  workoutSession: {
    data: [],
    currentGame: null,

    loadResult: {
      isSuccess: false,
      message: '',
    },
  },
};

function DashboardProviderReducer(state = initialState, action) {
  switch (action.type) {
    // case BQ_DATA_LOADED:
    //   return {
    //     ...state,
    //     BQ: {
    //       data: action.payload,
    //       loadResult: { isSuccess: true, message: '' },
    //     },
    //   };
    // case BQ_DATA_LOAD_ERROR:
    //   return {
    //     ...state,
    //     BQ: {
    //       data: state.BQ.data,
    //       loadResult: { isSuccess: false, message: 'Can not load bq data' },
    //     },
    //   };
    case TRAINING_CALENDAR_DATA_LOADED:
      return {
        ...state,
        trainingCalendar: {
          data: action.payload,
          loadResult: { isSuccess: true, message: '' },
        },
      };
    case TRAINING_CALENDAR_DATA_LOAD_ERROR:
      return {
        ...state,
        trainingCalendar: {
          data: state.trainingCalendar.data,
          loadResult: {
            isSuccess: false,
            message: 'Can not load trainingCalendar data',
          },
        },
      };
    case WORKOUT_SESSION_DATA_LOADED:
      const todayWorkOutData = action.payload;
      const workoutGames = todayWorkOutData.filter(item => !item.points);
      const nextGame = workoutGames.length > 0 ? workoutGames[0].game : null;

      return {
        ...state,
        workoutSession: {
          data: todayWorkOutData,
          nextGame,
          loadResult: { isSuccess: true, message: '' },
        },
      };
    case WORKOUT_SESSION_DATA_LOAD_ERROR:
      return {
        ...state,
        workoutSession: {
          data: state.workoutSession.data,
          nextGame: state.workoutSession.nextGame,
          loadResult: {
            isSuccess: false,
            message: 'Can not load workoutSession data',
          },
        },
      };
    default:
      return state;
  }
}

export default DashboardProviderReducer;
