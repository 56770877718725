import loadable from 'loadable-components';

const BlogPageLoadable = loadable(() => import('./BlogPage'));
const GamesForSeniorsArticleLoadable = loadable(() => import('./Articles/GamesForSeniors/Article'));
const BestIQGamesArticleLoadable = loadable(() => import('./Articles/BestOnlineIQTests/Article'));
const AverageIQArticleLoadable = loadable(() => import('./Articles/AverageIQ/Article'));
const MemoryTrainingArticleLoadable = loadable(() => import('./Articles/MemoryTraining/Article'));
const DoVideoGamesRotYourBrainLoadable = loadable(() => import('./Articles/DoVideoGamesRotYourBrain/Article'));
const CelebsIQLoadable = loadable(() => import('./Articles/CelebsIQ/Article'));
const ElonIQLoadable = loadable(() => import('./Articles/ElonIQ/Article'));
const IqRangesLoadable = loadable(() => import('./Articles/IqRanges/Article'));
const HawkingIqLoadable = loadable(() => import('./Articles/HawkingIq/Article'));
const HelpMemoryLossLoadable = loadable(() => import('./Articles/HelpMemoryLoss/Article'));
const BestMemoryImprovementGamesLoadable = loadable(() => import('./Articles/BestMemoryImprovementGames/Article'));
const StepsToTrainBrainLoadable = loadable(() => import('./Articles/StepsToTrainBrain/Article'));
const WhatDoesIqMeanLoadable = loadable(() => import('./Articles/WhatDoesIqMean/Article'));
const WhatIsWorkingMemoryLoadable = loadable(() => import('./Articles/WhatIsWorkingMemory/Article'));
const FluidVsCrystallizedIntelligenceLoadable = loadable(() => import('./Articles/FluidVsCrystallizedIntelligence/Article'));
const WaysToImproveYourConcentrationLoadable = loadable(() => import('./Articles/WaysToImproveYourConcentration/Article'));
const BriefGuideToCognitiveSkillsLoadable = loadable(() => import('./Articles/BriefGuideToCognitiveSkills/Article'));
const WaysToDevelopYourMentalAgilityLoadable = loadable(() => import('./Articles/WaysToDevelopYourMentalAgility/Article'));
const WhatIsLongTermMemoryLoadable = loadable(() => import('./Articles/WhatIsLongTermMemory/Article'));
const HowToBoostVisualSpatialIntelligenceLoadable = loadable(() => import('./Articles/HowToBoostVisualSpatialIntelligence/Article'));
const HowToRecognizeAndReduceStressLoadable = loadable(() => import('./Articles/HowToRecognizeAndReduceStress/Article'));
const TheEffectsOfExerciseOnTheBrainLoadable = loadable(() => import('./Articles/TheEffectsOfExerciseOnTheBrain/Article'));
const WhatIsNeuroplasticityLoadable = loadable(() => import('./Articles/WhatIsNeuroplasticity/Article'));
const TryTheseTipsToGetRidOfBrainFogLoadable = loadable(() => import('./Articles/TryTheseTipsToGetRidOfBrainFog/Article'));

export {
  HawkingIqLoadable,
  IqRangesLoadable,
  CelebsIQLoadable,
  BlogPageLoadable,
  GamesForSeniorsArticleLoadable,
  BestIQGamesArticleLoadable,
  AverageIQArticleLoadable,
  MemoryTrainingArticleLoadable,
  DoVideoGamesRotYourBrainLoadable,
  ElonIQLoadable,
  HelpMemoryLossLoadable,
  BestMemoryImprovementGamesLoadable,
  StepsToTrainBrainLoadable,
  WhatDoesIqMeanLoadable,
  WhatIsWorkingMemoryLoadable,
  FluidVsCrystallizedIntelligenceLoadable,
  WaysToImproveYourConcentrationLoadable,
  BriefGuideToCognitiveSkillsLoadable,
  WaysToDevelopYourMentalAgilityLoadable,
  WhatIsLongTermMemoryLoadable,
  HowToBoostVisualSpatialIntelligenceLoadable,
  HowToRecognizeAndReduceStressLoadable,
  TheEffectsOfExerciseOnTheBrainLoadable,
  WhatIsNeuroplasticityLoadable,
  TryTheseTipsToGetRidOfBrainFogLoadable,
};
