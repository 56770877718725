import React, { PureComponent } from 'react';
import posthog from 'posthog-js';
import ErrorIndicator from '../ErrorIndicator/ErrorIndicator';

class ErrorBoundary extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      message: '',
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, message: error.message };
  }

  componentDidCatch(error) {
    posthog.capture('User had error', {
      page: document.URL,
      referrer: document.referrer,
      message: error.message,
      info: error.info,
    });
    // eslint-disable-next-line no-console
    console.error(error);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorIndicator message={this.state.message} />;
    }
    // eslint-disable-next-line react/prop-types
    return this.props.children;
  }
}

export default ErrorBoundary;
