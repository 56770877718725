import loadable from 'loadable-components';
import {
  BlogPageLoadable,
  GamesForSeniorsArticleLoadable,
  BestIQGamesArticleLoadable,
  AverageIQArticleLoadable,
  MemoryTrainingArticleLoadable,
  DoVideoGamesRotYourBrainLoadable,
  CelebsIQLoadable,
  ElonIQLoadable,
  IqRangesLoadable,
  HawkingIqLoadable,
  HelpMemoryLossLoadable,
  BestMemoryImprovementGamesLoadable,
  StepsToTrainBrainLoadable,
  WhatDoesIqMeanLoadable,
  WhatIsWorkingMemoryLoadable,
  FluidVsCrystallizedIntelligenceLoadable,
  WaysToImproveYourConcentrationLoadable,
  BriefGuideToCognitiveSkillsLoadable,
  WaysToDevelopYourMentalAgilityLoadable,
  WhatIsLongTermMemoryLoadable,
  HowToBoostVisualSpatialIntelligenceLoadable,
  HowToRecognizeAndReduceStressLoadable,
  TheEffectsOfExerciseOnTheBrainLoadable,
  WhatIsNeuroplasticityLoadable,
  TryTheseTipsToGetRidOfBrainFogLoadable,
} from '../containers/BlogPage/BlogRoutes';
import AccountPage from '../containers/AccountPage/Loadable';
import EditAccountPage from '../containers/EditAccountPage/Loadable';
import GamesPage from '../containers/GamesPage/Loadable';
import GamePage from '../containers/GamePage/Loadable';
import AboutUsPage from '../containers/AboutUsPage/Loadable';

import HomePage from '../containers/HomePage/Loadable';
import BrainProfilePage from '../containers/BrainProfilePage/Loadable';
import IqTestPage from '../containers/IqTestPage/Loadable';
import LandingTestsGamePage from '../containers/LandingQuizPage/Loadable';
import LandingSaveResultsPage from '../containers/LandingSaveResultsPage/Loadable';
import LandingSalesPage from '../containers/LandingSalesPage/Loadable';
import LandingCheckOutPage from '../containers/LandingCheckOutPage/Loadable';
import ChallengeSkillsPage from '../containers/ChallengeSkillsPage/Loadable';
import DashboardPage from '../containers/DashboardPage/Loadable';
import PersonalityStartPage from '../containers/PersonalityTestStartPage/Loadable';
import VocabTestStartPage from '../containers/VocabTestStartPage/Loadable';
import TermsOfUsePage from '../containers/TermsOfUsePage/Loadable';
import SupportCenterPage from '../containers/SupportCenterPage/Loadable';
import PrivacyPolicyPage from '../containers/PrivacyPolicyPage/Loadable';
import CookiePolicyPage from '../containers/CookiePolicyPage/Loadable';
import RefundPolicyPage from '../containers/RefundPolicyPage/Loadable';
import PurchasePage from '../containers/PurchasePage/Loadable';
import CareersPage from '../containers/CareersPage/Loadable';
import ContactPage from '../containers/ContactPage/Loadable';
import LoginPage from '../containers/LoginPage/Loadable';
import SignoutPage from '../containers/SignoutPage/Loadable';
import SignupPage from '../containers/SignupPage/Loadable';
import LandingPurchaseConfirmationPage from '../containers/LandingPurchaseConfirmationPage/Loadable';
import LandingSignupPage from '../containers/LandingSignupPage/Loadable';
import PricingPage from '../containers/PricingPage/Loadable';
import SubscriptionPage from '../containers/SubscriptionPage/Loadable';
import SubscriptionConfirmationPage from '../containers/SubscriptionConfirmationPage/Loadable';
import WorkoutPage from '../containers/WorkoutPage/Loadable';
import UserRecoverPage from '../containers/UserRecoverPage/Loadable';
import ResetPasswordPage from '../containers/ResetPasswordPage/Loadable';
import LandingResendEmail from '../containers/LandingResendEmailPage';
import UnsubscribePage from '../containers/UnsubscribePage/Loadable';
import PartnersPage from '../containers/PartnersPage/Loadable';
import TestimonialsPage from '../containers/TestimonialsPage';
import VocabTestPage from '../containers/LandingVocabQuizPage';
import LoginWithEmailPage from '../containers/LoginWithEmailPage';

export const routes = {
  account: {
    path: '/account',
    component: AccountPage,
    props: { exact: true },
  },
  editLoginInformation: {
    path: '/editLoginInformation',
    component: EditAccountPage,
    props: { exact: true },
  },
  games: {
    path: '/games',
    component: GamesPage,
    props: { exact: true },
  },
  game: {
    path: '/game/:id',
    component: GamePage,
    props: { exact: true },
  },
  gamesInfo: {
    path: '/games-info',
    component: loadable(() => import('../containers/GamesInfoPage')),
    props: { exact: true },
  },
  gameInfo: {
    path: '/games-info/:id',
    component: loadable(() => import('../containers/GameInfoPage')),
    props: { exact: true },
  },
  brainProfile: {
    path: '/brain-profile',
    component: BrainProfilePage,
    props: { exact: true },
  },
  start: {
    path: '/start',
    component: IqTestPage,
    props: { exact: true },
  },
  personalityStart: { // TODO: remove
    path: '/personality/start',
    component: PersonalityStartPage,
  },
  vocabStart: { // TODO: remove
    path: '/vocabtest/start',
    component: VocabTestStartPage,
  },
  quiz: {
    path: '/quiz',
    component: LandingTestsGamePage,
    props: { exact: true },
  },
  vocabQuiz: { // TODO: remove
    path: '/vocab/quiz',
    component: VocabTestPage,
    props: { exact: true },
  },
  purchaseConfirmation: { // TODO: doesn't work on old master with webpack. Firebase error
    path: '/purchase-confirmation',
    component: LandingPurchaseConfirmationPage,
    props: { exact: true },
  },
  save: {
    path: '/save',
    component: LandingSaveResultsPage,
    props: { exact: true },
  },
  result: {
    path: '/result',
    component: LandingSalesPage,
    props: { exact: true },
  },
  checkout: {
    path: '/checkout',
    component: LandingCheckOutPage,
    props: { exact: true },
  },
  challengeSkills: { // TODO: do we really need it?
    path: '/challenge-skills',
    component: ChallengeSkillsPage,
    props: { exact: true },
  },
  dashboard: {
    path: '/dashboard',
    component: DashboardPage,
    props: { exact: true },
  },
  about: { // TODO: do we really need it?
    path: '/about',
    component: AboutUsPage,
    props: { exact: true },
  },
  termsOfUse: {
    path: '/terms-of-use',
    component: TermsOfUsePage,
    props: { exact: true },
  },
  supportCenter: {
    path: '/support-center',
    component: SupportCenterPage,
    props: { exact: true },
  },
  privacy: {
    path: '/privacy',
    component: PrivacyPolicyPage,
    props: { exact: true },
  },
  cookiePolicy: { // TODO: remove
    path: '/cookie-policy',
    component: CookiePolicyPage,
    props: { exact: true },
  },
  refundPolicy: {
    path: '/refund-policy',
    component: RefundPolicyPage,
    props: { exact: true },
  },
  purchase: {
    path: '/purchase',
    component: PurchasePage,
    props: { exact: true },
  },
  pricing: {
    path: '/pricing',
    component: PricingPage,
    props: { exact: true },
  },
  workout: {
    path: '/workout',
    component: WorkoutPage,
    props: { exact: true },
  },
  unsubscribe: {
    path: '/unsubscribe',
    component: UnsubscribePage,
    props: { exact: true },
  },
  subscription: {
    path: '/subscription/:type',
    component: SubscriptionPage,
    props: { exact: true },
  },
  subscriptionConfirmation: {
    path: '/subscription-confirmation/:status?',
    component: SubscriptionConfirmationPage,
    props: { exact: true },
  },
  careers: {
    path: '/careers',
    component: CareersPage,
    props: { exact: true },
  },
  contact: {
    path: '/contact',
    component: ContactPage,
    props: { exact: true },
  },
  login: {
    path: '/login',
    component: LoginPage,
    props: { exact: true },
  },
  signout: {
    path: '/signout',
    component: SignoutPage,
    props: { exact: true },
  },
  signup: {
    path: '/signup',
    component: SignupPage,
    props: { exact: true },
  },
  signUpUser: {
    path: '/sign-up/:userId',
    component: LandingSignupPage,
    props: { exact: true },
  },
  userRecover: {
    path: '/user-recover',
    component: UserRecoverPage,
    props: { exact: true },
  },
  resetPasswordToken: {
    path: '/reset-password/:token',
    component: ResetPasswordPage,
    props: { exact: true },
  },
  resendEmailToken: {
    path: '/resend-email/:token',
    component: LandingResendEmail,
    props: { exact: true },
  },
  partners: {
    path: '/partners',
    component: PartnersPage,
    props: { exact: true },
  },
  blogList: {
    path: '/blog-list',
    component: BlogPageLoadable,
    props: { exact: true, strict: true },
  },
  blogSeniorsGames: {
    path: '/blog/best-brain-games-for-seniors',
    component: GamesForSeniorsArticleLoadable,
    props: { exact: true },
  },
  blogBestIqTests: {
    path: '/blog/best-online-iq-tests',
    component: BestIQGamesArticleLoadable,
    props: { exact: true },
  },
  blogAverageIq: {
    path: '/blog/what-is-the-average-iq-how-to-improve-yours',
    component: AverageIQArticleLoadable,
    props: { exact: true },
  },
  blogMemoryTraining: {
    path: '/blog/memory-training-101',
    component: MemoryTrainingArticleLoadable,
    props: { exact: true },
  },
  blogVideoGames: {
    path: '/blog/do-video-games-rot-your-brain',
    component: DoVideoGamesRotYourBrainLoadable,
    props: { exact: true },
  },
  blogCelebsIq: {
    path: '/blog/5-celebrities-highest-iq',
    component: CelebsIQLoadable,
    props: { exact: true },
  },
  blogElonIq: {
    path: '/blog/elon-musk-iq',
    component: ElonIQLoadable,
    props: { exact: true },
  },
  blogIqRanges: {
    path: '/blog/ranges-for-iq-scores',
    component: IqRangesLoadable,
    props: { exact: true },
  },
  blogHawkingIq: {
    path: '/blog/stephen-hawkings-iq',
    component: HawkingIqLoadable,
    props: { exact: true },
  },
  blogHelpMemoryLoss: {
    path: '/blog/how-help-short-term-memory-loss',
    component: HelpMemoryLossLoadable,
    props: { exact: true },
  },
  blogBestMemoryImprovement: {
    path: '/blog/best-memory-improvement-games-for-adults',
    component: BestMemoryImprovementGamesLoadable,
    props: { exact: true },
  },
  blogTrainBrain: {
    path: '/blog/4-simple-steps-train-your-brain',
    component: StepsToTrainBrainLoadable,
    props: { exact: true },
  },
  testimonials: {
    path: '/testimonials',
    component: TestimonialsPage,
    props: { exact: true },
  },
  loginWithEmail: {
    path: '/login-with-email',
    component: LoginWithEmailPage,
    props: { exact: true },
  },
  home: {
    path: '/',
    component: HomePage,
    props: { exact: true },
  },
  blogWhatDoesIqMean: {
    path: '/blog/what-does-iq-mean',
    component: WhatDoesIqMeanLoadable,
    props: { exact: true },
  },
  blogWhatIsWorkingMemory: {
    path: '/blog/what-is-working-memory',
    component: WhatIsWorkingMemoryLoadable,
    props: { exact: true },
  },
  blogBriefGuideToCognitiveSkills: {
    path: '/blog/brief-guide-to-cognitive-skills',
    component: BriefGuideToCognitiveSkillsLoadable,
    props: { exact: true },
  },
  blogFluidVsCrystallizedIntelligence: {
    path: '/blog/fluid-vs-crystallized-intelligence',
    component: FluidVsCrystallizedIntelligenceLoadable,
    props: { exact: true },
  },
  blogWaysToImproveYourConcentration: {
    path: '/blog/ways-to-improve-your-concentration',
    component: WaysToImproveYourConcentrationLoadable,
    props: { exact: true },
  },
  blogWaysToDevelopYourMentalAgility: {
    path: '/blog/ways-to-develop-your-mental-agility',
    component: WaysToDevelopYourMentalAgilityLoadable,
    props: { exact: true },
  },
  blogWhatIsLongTermMemory: {
    path: '/blog/what-is-long-term-memory',
    component: WhatIsLongTermMemoryLoadable,
    props: { exact: true },
  },
  blogHowToBoostVisualSpatialIntelligence: {
    path: '/blog/boost-visual-spatial-intelligence',
    component: HowToBoostVisualSpatialIntelligenceLoadable,
    props: { exact: true },
  },
  blogHowToRecognizeAndReduceStress: {
    path: '/blog/how-to-recognize-stress',
    component: HowToRecognizeAndReduceStressLoadable,
    props: { exact: true },
  },
  blogTheEffectsOfExerciseOnTheBrain: {
    path: '/blog/effects-of-exercise-on-the-brain',
    component: TheEffectsOfExerciseOnTheBrainLoadable,
    props: { exact: true },
  },
  blogWhatIsNeuroplasticityLoadable: {
    path: '/blog/what-is-neuroplasticity',
    component: WhatIsNeuroplasticityLoadable,
    props: { exact: true },
  },
  blogTryTheseTipsToGetRidOfBrainFogLoadable: {
    path: '/blog/tips-to-get-rid-of-brain-fog',
    component: TryTheseTipsToGetRidOfBrainFogLoadable,
    props: { exact: true },
  },
  review: {
    path: '/review',
    component: loadable(() => import('../containers/ReviewPage')),
    props: { exact: true },
  },
};

export const routesRedirects = {
  blogRedirect: {
    from: '/blog',
    to: '/blog-list',
  },
  blogListRedirect: {
    from: '/blog-list/',
    to: '/blog-list',
  },
  blogSeniorsGames: {
    from: '/blog/seniors-games',
    to: '/blog/best-brain-games-for-seniors',
  },
  blogSeniorsGamesTwo: {
    from: '/blog/brain-games-for-seniors',
    to: '/blog/best-brain-games-for-seniors',
  },
  blogAverageIq: {
    from: '/blog/average-iq',
    to: '/blog/what-is-the-average-iq-how-to-improve-yours',
  },
  blogMemoryTraining: {
    from: '/blog/memory-training',
    to: '/blog/memory-training-101',
  },
  blogVideoGames: {
    from: '/blog/video-games-brain',
    to: '/blog/do-video-games-rot-your-brain',
  },
  blogTrainBrain: {
    from: '/blog/train-brain',
    to: '/blog/4-simple-steps-train-your-brain',
  },
  blogBestIqTests: {
    from: '/blog/best-iq-tests',
    to: '/blog/best-online-iq-tests',
  },
  celebritiesIq: {
    from: '/blog/celebrities-iq',
    to: '/blog/5-celebrities-highest-iq',
  },
  elonMuskIq: {
    from: '/blog/elon-iq',
    to: '/blog/elon-musk-iq',
  },
  iqRanges: {
    from: '/blog/iq-ranges',
    to: '/blog/ranges-for-iq-scores',
  },
  hawkingIq: {
    from: '/blog/hawking-iq',
    to: '/blog/stephen-hawkings-iq',
  },
  bestMemoryImprovement: {
    from: '/blog/best-memory-improvement',
    to: '/blog/best-memory-improvement-games-for-adults',
  },
};
