/*
 *
 * IqTest actions
 *
 */

export const SET_TEST_CONFIGS = 'app/IqTest/SET_TEST_CONFIGS';
export const setTestConfigs = (configs, testId) => ({
  type: SET_TEST_CONFIGS,
  configs,
  testId,
});

export const START_LOADING = 'app/IqTest/START_LOADING';
export const startLoading = () => ({
  type: START_LOADING,
});

export const START_TEST = 'app/IqTest/START_TEST';
export const startTest = () => ({
  type: START_TEST,
});

export const UPDATE_QUESTION = 'app/IqTest/UPDATE_QUESTION';
export const updateQuestion = number => ({
  type: UPDATE_QUESTION,
  number,
});

export const CHOOSE_ANSWER = 'app/IqTest/CHOOSE_ANSWER';
export const chooseAnswer = (questionType, questionTypeNumber, answer) => ({
  type: CHOOSE_ANSWER,
  questionType,
  questionTypeNumber,
  answer,
});

export const FINISH_TEST = 'app/IqTest/FINISH_TEST';
export const finishTest = totalTime => ({
  type: FINISH_TEST,
  totalTime,
});

export const CALCULATE_RESULT = 'app/IqTest/CALCULATE_RESULT';
export const calculateResult = () => ({
  type: CALCULATE_RESULT,
});

export const SET_TEST_RESULT = 'app/IqTest/SET_TEST_RESULT';
export const setTestResult = scores => ({
  type: SET_TEST_RESULT,
  scores,
});

export const FINISH_PERSONALITY_TEST = 'app/IqTest/FINISH_PERSONALITY_TEST';
export const finishPersonalityTest = ({ results, totalTime }) => ({
  type: FINISH_PERSONALITY_TEST,
  results,
  totalTime,
});
