/*
 *
 * LandingCheckOutPage reducer
 *
 */

import { CHARGE_REQUEST_SUCCESS, PAYMENT_ERROR, START_PROCESSING_PAYMENT, CHANGE_PRICE } from './actions';

export const initialState = {
  isPaid: false,
  processingPayment: false,
  errors: '',
  price: '',
};

function landingCheckOutPageReducer(state = initialState, action) {
  switch (action.type) {
    case START_PROCESSING_PAYMENT:
      return {
        ...state,
        processingPayment: true,
      };
    case CHARGE_REQUEST_SUCCESS:
      return {
        ...state,
        isPaid: true,
        processingPayment: false,
      };
    case PAYMENT_ERROR:
      return {
        ...state,
        errors: action.message || 'Your CC information is wrong, please try again.',
        processingPayment: false,
      };
    case CHANGE_PRICE: {
      return {
        ...state,
        price: action.price,
      };
    }
    default:
      return state;
  }
}

export default landingCheckOutPageReducer;
