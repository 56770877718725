/*
 *
 * LandingResendEmailPage constants
 *
 */

export const LANDING_RESEND_EMAIL = 'app/LandingResendEmailPage/LANDING_RESEND_EMAIL';
export const LANDING_RESEND_EMAIL_SUCCESS = 'app/LandingResendEmailPage/LANDING_RESEND_EMAIL_SUCCESS';
export const LANDING_RESEND_EMAIL_FAIL = 'app/LandingResendEmailPage/LANDING_RESEND_EMAIL_FAIL';
export const LANDING_RESEND_EMAIL_PROCESSING = 'app/LandingResendEmailPage/LANDING_RESEND_EMAIL_PROCESSING';
