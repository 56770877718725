/**
 *
 * MultipleLoading
 *
 */

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Loading from '../Loading';

const Heading = styled.h3`
  @media (max-width: 767.98px) {
    font-size: 19px;
  }
`;

function MultipleLoading({ currentSection, onCategoryLoadComplete }) {
  return (
    <div>
      <Heading className="noselect">YOUR VERBAL TEST PROGRESS</Heading>

      <Loading title="Section 1: 16 Personalities" titleStyle={2} categoryLoaded={currentSection > 1} onCategoryLoadComplete={onCategoryLoadComplete} />
      {currentSection < 2 ? null : (
        <Loading title="Section 2: Word Preference" titleStyle={2} categoryLoaded={currentSection > 2} onCategoryLoadComplete={onCategoryLoadComplete} />
      )}
    </div>
  );
}

MultipleLoading.propTypes = {
  currentSection: PropTypes.number.isRequired,
  onCategoryLoadComplete: PropTypes.func,
};

export default MultipleLoading;
