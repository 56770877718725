import { LANDING_RESEND_EMAIL, LANDING_RESEND_EMAIL_FAIL, LANDING_RESEND_EMAIL_PROCESSING, LANDING_RESEND_EMAIL_SUCCESS } from './constants';

export function resend(id) {
  return {
    type: LANDING_RESEND_EMAIL,
    payload: { id },
  };
}

export function processing() {
  return {
    type: LANDING_RESEND_EMAIL_PROCESSING,
    payload: {},
  };
}

export function success() {
  return {
    type: LANDING_RESEND_EMAIL_SUCCESS,
    payload: {},
  };
}

export function fail(error) {
  return {
    type: LANDING_RESEND_EMAIL_FAIL,
    payload: { error },
  };
}
