import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AccessibilityIcon, AccessibilityWrapper, SmallAccessibilityIcon, AccessibilityScript } from '../Accessibility';
import { FooterWrapper, BareButton, FooterDesktop, FooterMobile, LinksBlock, AccessibilityCol, CopyrightCol, NavCol } from './LoggedInFooter.style';

// eslint-disable-next-line react/prop-types
export default ({ copyrightText }) => {
  const [shouldLoadAccessibility, setShouldLoadAccessibility] = useState(false);
  const handleA11yClick = () => {
    if (window.interdeal) {
      window.interdeal?.a11y?.openMenu()
    } else {
      setShouldLoadAccessibility(true)
    }
  }
  return (
  <FooterWrapper className="footer">
    {shouldLoadAccessibility && <AccessibilityScript />}
    <div className="container">
      <FooterDesktop className="row align-items-center d-none d-md-flex">
        <AccessibilityCol className="col-6 px-0 order-last">
          <BareButton className="nav-link pl-4 pl-md-0" onClick={handleA11yClick}>
            <SmallAccessibilityIcon />
            <span>Accessibility</span>
          </BareButton>
        </AccessibilityCol>
        <CopyrightCol className="col-6 px-0 order-last">
          <div className="nav nav-trim copyright">
            <span>
              Copyright &copy;
              {copyrightText}
            </span>
          </div>
        </CopyrightCol>
        <NavCol className="col-12 px-0">
          <div className="nav nav-trim nav-uppercase">
            <BareButton className="nav-link pl-4 pl-md-0" onClick={handleA11yClick}>
              <SmallAccessibilityIcon />
              <span>Accessibility</span>
            </BareButton>
            <Link to="/support-center" className="nav-link">
              Support Center
            </Link>
            <Link to="/terms-of-use" className="nav-link">
              Terms of Use
            </Link>
            <Link to="/privacy" className="nav-link">
              Privacy policy
            </Link>
            <Link to="/careers" className="nav-link">
              Careers
            </Link>
            <Link to="/contact" className="nav-link pr-4 pr-md-0">
              Contact Us
            </Link>
          </div>
        </NavCol>
      </FooterDesktop>
      <FooterMobile className="row align-items-start d-md-none">
        <div className="col-6 col-md-3">
          <div className="nav">
            <Link to="/support-center" className="nav-link">
              Support Center
            </Link>
            <Link to="/terms-of-use" className="nav-link">
              Terms of Use
            </Link>
            <Link to="/privacy" className="nav-link">
              Privacy policy
            </Link>
          </div>
        </div>
        <div className="col-6 col-md-3">
          <div className="nav">
            <Link to="/careers" className="nav-link">
              Careers
            </Link>
            <Link to="/contact" className="nav-link">
              Contact Us
            </Link>
          </div>
        </div>
        <div className="col-12 col-md-3 d-flex justify-content-center">
          <LinksBlock>
            <AccessibilityWrapper onClick={handleA11yClick}>
              <AccessibilityIcon />
            </AccessibilityWrapper>
            <div className="nav nav-trim copyright d-md-none">
              &copy;
              {copyrightText}
            </div>
          </LinksBlock>
        </div>
      </FooterMobile>
    </div>
  </FooterWrapper>
)};
