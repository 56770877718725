export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

export const COLOR_PALETTE = {
  white: '#FFFFFF',
  orange: '#F6813F',
  lightGray: '#C4C4C4',
  lightBlue1: '#A3CCE9',
  lightBlue2: '#80AFDA',
  lightBlue4: '#DEE8F2',
  darkBlue1: '#588ABE',
  darkBlue2: '#386192',
  blackBlue: '#1F2D44',
};

export const MEDIA_QUERIES = {
  mobile: {
    min: '0px',
    max: '768px',
  },
  tablet: {
    print: true,
    min: '769px',
    max: '1023px',
  },
  desktop: {
    min: '1024px',
    max: '1439px',
  },
  wideScreen: {
    min: '1440px',
    max: '1919px',
  },
  fullHD: {
    min: '1920px',
    max: null,
  },
};
