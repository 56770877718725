import invariant from 'invariant';

/**
 * Validate the shape of redux store
 */
export default function checkStore(store) {
  const isFunction = (value) => typeof value === 'function';
  const isObject = (value) => value !== null && typeof value === 'object';
  const conformsTo = (object, shape) => 
    Object.keys(shape).every(key => shape[key](object[key]));

  const shape = {
    dispatch: isFunction,
    subscribe: isFunction,
    getState: isFunction,
    replaceReducer: isFunction,
    runSaga: isFunction,
    injectedReducers: isObject,
    injectedSagas: isObject,
  };
  invariant(conformsTo(store, shape), '(app/utils...) injectors: Expected a valid redux store');
}
