import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AccessibilityWrapper, AccessibilityIcon, AccessibilityScript } from '../Accessibility';
import { Footer, FooterContainer, LinksBlock, MobilePricingLinks } from './LoggedOutFooter.style';

// eslint-disable-next-line react/prop-types
export default ({ copyrightText }) => {
  const [shouldLoadAccessibility, setShouldLoadAccessibility] = useState(false);
  const handleA11yClick = () => {
    if (window.interdeal) {
      window.interdeal?.a11y?.openMenu()
    } else {
      setShouldLoadAccessibility(true)
    }
  }
  return (
  <Footer className="container-fluid">
    {shouldLoadAccessibility && <AccessibilityScript />}
    <FooterContainer className="row align-items-start">
      <div className="col-6 col-md-3">
        <div className="heading">Links</div>
        <div className="nav flex-column">
          <Link to="/support-center" className="nav-link nav-link-footer">
            Support center
          </Link>
          <Link to="/careers" className="nav-link nav-link-footer">
            Careers
          </Link>
          <Link to="/contact" className="nav-link nav-link-footer">
            Contact Us
          </Link>
          <Link to="/blog-list" className="nav-link nav-link-footer">
            Blog
          </Link>
          <Link to="/games-info" className="nav-link nav-link-footer">
            Games
          </Link>
        </div>
      </div>
      <div className="col-3 d-none d-md-block">
        <div className="heading d-none d-md-block opacity-0">Links</div>
        <div className="nav flex-column">
          <Link to="/refund-policy" className="nav-link nav-link-footer">
            Refund policy
          </Link>
          <Link to="/pricing" className="nav-link nav-link-footer">
            Pricing
          </Link>
        </div>
      </div>
      <div className="col-6 col-md-3">
        <div className="heading">Legal</div>
        <div className="nav flex-column">
          <Link to="/terms-of-use" className="nav-link nav-link-footer">
            Terms & Conditions
          </Link>
          <Link to="/privacy" className="nav-link nav-link-footer">
            Privacy policy
          </Link>
          <Link to="/cookie-policy" className="nav-link nav-link-footer">
            Cookie policy
          </Link>
          <MobilePricingLinks className="d-md-none">
            <Link to="/refund-policy" className="nav-link nav-link-footer">
              Refund policy
            </Link>
            <Link to="/pricing" className="nav-link nav-link-footer">
              Pricing
            </Link>
          </MobilePricingLinks>
        </div>
      </div>
      <div className="col-12 col-md-3 d-flex justify-content-center">
        <LinksBlock>
          <AccessibilityWrapper onClick={handleA11yClick}>
            <AccessibilityIcon />
          </AccessibilityWrapper>
        </LinksBlock>
      </div>
      <div className="col-12 d-none d-md-block">
        <div className="nav nav-trim copyright">
          &copy;
          {copyrightText}
        </div>
      </div>
    </FooterContainer>
  </Footer>
)};
