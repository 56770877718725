import styled, { keyframes } from 'styled-components';

const scale = keyframes`
  0% {
    transform: scale(1, 1);
    opacity: 1;
  }

  100% {
    transform: scale(1.4, 1.9);
    opacity: 0;
  }
`;

const Button = styled.button`
  display: inline-block;
  position: relative;
  background-color: #c87b58;
  z-index: 1;
  font-size: 18px;
  white-space: nowrap;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 7px 32px 0px;
  font-weight: 500;
  color: rgb(255, 255, 255) !important;
  border-radius: 50px;
  padding: 18px 30px;
  min-width: 200px;
  border-width: 3px;
  border-style: solid;
  border-color: #c87b58;
  border-image: initial;
  transition: all 0.1s linear 0s;
  cursor: pointer;
  @media (min-width: 150px) {
    font-size: 16px;
    padding: 12px 28px;
  }
  @media (min-width: 360px) {
    font-size: 20px;
    padding: 18px 30px;
  }
  @media (min-width: 768px) {
    font-size: 23px;
    padding: 18px 34px;
  }
  :hover {
    border-color: #f35710;
  }
  ::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: #c87b58;
    pointer-events: none;
    border-radius: 50px;
    animation: ${scale} 2.5s cubic-bezier(0.66, 0, 0, 1) 0s infinite normal none running;
  }
`;

export default Button;
