import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

/** Constants */
import { device } from '../../styles/devices';

const Wrapper = styled.div`
  & .dropdown-menu {
    left: auto;
    right: 0;
  }

  display: none;
  @media ${device.laptop} {
    display: block;

    & .dropdown-menu {
      margin-top: 24px;
    }

    & .dropdown-item {
      margin-bottom: 20px;
    }

    & .dropdown-item.logout {
      margin-top: 30px;
      margin-bottom: 20px;
    }
  }
`;

export default class Dropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  render() {
    return (
      <Wrapper className={this.state.isOpen ? 'show dropdown' : 'dropdown'} style={{ whiteSpace: 'nowrap' }}>
        <span onClick={this.handleClick} onKeyPress={this.handleClick} role="link" tabIndex={0} className="dropdown-toggle nav-link link-bold">
          {this.props.userEmail}
        </span>
        <div className={`dropdown-menu${this.state.isOpen ? ' show ' : ''}`}>
          <Link onClick={this.handleClick} onKeyPress={this.handleClick} to="/dashboard" className="dropdown-item">
            Dashboard
          </Link>
          <Link onClick={this.handleClick} onKeyPress={this.handleClick} to="/account" className="dropdown-item">
            Account
          </Link>
          <Link onClick={this.handleClick} onKeyPress={this.handleClick} to="/signout" className="dropdown-item logout">
            Logout
          </Link>
        </div>
      </Wrapper>
    );
  }

  handleClick = () => {
    this.setState(({ isOpen }) => ({
      isOpen: !isOpen,
    }));
  };
}

Dropdown.propTypes = {
  userEmail: PropTypes.string,
};
