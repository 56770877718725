import styled from 'styled-components';
import { MEDIA_QUERIES } from '../../../utils/constants';

const Footer = styled.footer`
  background: #1f2d44;
  position: relative;
`;
const FooterContainer = styled.div`
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
  color: white;
  padding-top: 60px;
  padding-bottom: 30px;
  margin: auto;
  max-width: 1200px;

  @media (max-width: 1200px) {
    max-width: 800px;
  }

  @media (min-width: ${MEDIA_QUERIES.mobile.max}) {
    padding: 30px 0px;
  }

  .nav-link {
    font-weight: 400;
    font-family: Poppins;
    font-size: 16px;
    color: white;
    @media (min-width: ${MEDIA_QUERIES.mobile.max}) {
      font-size: 14px;
    }

    &.nav-link-footer {
      padding: 10px 0;
      @media (min-width: ${MEDIA_QUERIES.mobile.max}) {
        padding: 2px 0;
      }
    }
  }
  .heading {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 20px;
  }
  .copyright {
    margin-top: 15px;
  }

  > [class*='col-'] {
    @media (max-width: ${MEDIA_QUERIES.mobile.max}) {
      padding-left: 10px;
      padding-right: 6px;
    }
  }
`;

const LinksBlock = styled.div`
  margin-left: auto;
  align-items: center;
  display: flex;
  flex-direction: column;
  .nav {
    margin-top: 22px;
  }

  svg + svg {
    margin-left: 20px;
  }

  @media (max-width: 767.98px) {
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: end;
    margin-top: 66px;
  }

  .copyright {
    font-weight: 400;
    font-family: Poppins;
    font-size: 16px;
    color: white;

    @media (min-width: ${MEDIA_QUERIES.mobile.max}) {
      font-size: 14px;
    }
  }
`;

const MobilePricingLinks = styled.span`
  margin-top: 30px;
`;

export { Footer, FooterContainer, LinksBlock, MobilePricingLinks };
