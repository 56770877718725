import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import history from './utils/history';
import * as Sentry from "@sentry/react";

import App from './containers/App';

import posthog from 'posthog-js';
import configureStore from './configureStore';

Sentry.init({
  dsn: 'https://19288c8a02bda83c59c0962f8561205d@o4507017622454272.ingest.us.sentry.io/4508325284937728',
  tracesSampleRate: 0.7,
  enabled: process.env.NODE_ENV !== 'development',
});

posthog.init(process.env.POSTHOG_API_KEY, {
  capture_pageleave: false,
  capture_pageview: false,
  loaded: ph => {
    if (!sessionStorage.getItem('pageview')) {
      ph.capture('$pageview');
      sessionStorage.setItem('pageview', 'true');
    }
    if (process.env.NODE_ENV === 'development') ph.debug();
  },
});

// Create redux store with history
const initialState = {};
const store = configureStore(initialState, history);

const render = () => {
  store.firebaseAuthIsReady.then(() => {
    ReactDOM.render(
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Sentry.ErrorBoundary>
            <App />
          </Sentry.ErrorBoundary>
        </ConnectedRouter>
      </Provider>,
      document.getElementById('app'),
    );
  });
};
render();
