const palette = {
  black: '#190B35',
  white: '#FFFFFF',
  accent: '#F6813F',
  blackBlue: '#1F2D44',
  darkBlue1: '#588ABE',
  darkBlue2: '#386192',
  lightBlue1: '#A3CCE9',
  lightBlue2: '#80AFDA',
  lightBlue3: '#BCD2E7',
  lightBlue4: '#DEE8F2',
  gray: '#F4F4F4',
  gray2: '#E1E1E1',
  disable: '#D9D9D9',
  ratingSuccess: '#ff8b49',
  ratingFailed: '#343a40',
  statusError: '#DE5A43',
};

export default palette;
