import styled from 'styled-components';
import iconArrowRight from '../../assets/img/iqtest/icon_arrow_right.png';

export const QuestionsWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  background-color: white;
`;

export const QuestionWrapper = styled.div`
  border: 1px solid #eee;
  @media (max-width: 767.98px) {
    margin-top: -68px;
  }
  text-align: center !important;
  & .img-fluid {
    max-height: 162px;
  }
  @media (min-width: 361px) {
    & .img-fluid {
      max-height: 200px;
    }
  }
  @media (min-width: 768px) {
    text-align: left !important;
    & .img-fluid {
      max-height: 100%;
    }
  }
`;

export const ChooseAnswerWrapper = styled.div`
  border: 1px solid #eee;
`;

export const QuestionHeading = styled.h2`
  font-size: 18px;
  color: #212529;
  text-align: left !important;
  font-weight: 300;
  @media (min-width: 768px) {
    font-size: 25px;
  }
`;

export const AnswerHeadingWrapper = styled.div`
  overflow: hidden;
`;

export const AnswerHeading = styled.div`
  font-size: 17px;
  color: #767676;
`;

export const SkipLink = styled.div`
  display: inline-block;
  color: #60b7d0;
  font-size: 17px;
  background: transparent url(${iconArrowRight}) center right no-repeat;
  padding-right: 15px;
  &:active,
  &:hover {
    color: #60b7d0;
  }
  cursor: pointer;
`;

export const ResultLink = styled.div`
  display: inline-block;
  color: #60b7d0;
  font-size: 17px;
  background: transparent url(${iconArrowRight}) center right no-repeat;
  padding-right: 15px;
  &:active,
  &:hover {
    color: #60b7d0;
  }
  cursor: pointer;
`;

export const AnswerLetter = styled.div`
  font-weight: bold;
  float: left;
  padding-right: 20px;
  width: 20px;
  @media (min-width: 768px) {
    padding-right: 25px;
  }
`;
export const AnswerPic = styled.img`
  cursor: pointer;
`;

export const AnswerRow = styled.div`
  transition: 0.1s linear;
  white-space: nowrap;
  & img {
    max-height: 54px;
  }
  &.mobile:active {
    background-color: #b4dfec;
  }
  &:not(.mobile):hover {
    background-color: #b4dfec;
    cursor: pointer;
  }
  @media (min-width: 361px) {
    & img {
      max-height: 70px;
    }
  }
  @media (min-width: 768px) {
    & img {
      max-height: 80px;
    }
  }
`;

export const CurrentSectionText = styled.p`
  margin-bottom: 0;
  font-size: 18px;
  opacity: 0.8;
  color: #212529;
  font-family: Montserrat, sans-serif;
  font-weight: 300;
  @media (max-width: 767.98px) {
    text-align: left;
    font-size: 18px;
    line-height: 1.3;
  }
`;

export const CurrentSectionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-items: flex-start;
`;

export const HorizontalLine = styled.hr`
  margin: 5px auto 10px;
  @media (min-width: 768px) {
    margin: 1rem auto;
  }
`;

export const QuizWrapper = styled.div`
  position: relative;
  z-index: 50;
  margin-bottom: 50px;
  display: inline-block;
  background-color: #fff;
  box-sizing: border-box;
  text-align: center;
  padding: 30px 20px 20px;
  @media (max-width: 767.98px) {
    padding-top: 10px;
  }
`;

export const SecureHeader = styled.h3`
  font-size: 20px;
  font-weight: 300 !important;
  position: relative;
  z-index: 10;
  margin-bottom: 20px;
  @media (max-width: 767.98px) {
    font-size: 16px;
    letter-spacing: 0;
    margin-bottom: 10px;
  }
`;

export const SecureImg = styled.img`
  @media (max-width: 767.98px) {
    max-width: 32px;
  }
`;
