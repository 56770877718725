export const getCookie = (name) => {
    const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) return match[2];
    return '';
};

export const setCookie = (name, value, options = { expires: 365 }) => {
  let expires = '';
  expires = '; expires=' + options.expires;
  const domain = options.domain ? `; domain=${options.domain}` : '';
  document.cookie = name + '=' + value + expires + domain + '; path=/';
};
