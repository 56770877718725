import styled from 'styled-components';

import { device } from '../../styles/constants';

export const LayoutCentered = styled.div`
  min-height: 100vh;
  background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};
  background-image: ${({ backgroundImage }) => (backgroundImage ? `url(${backgroundImage})` : 'none')};
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;

  position: relative;
  z-index: 4;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;

  margin-top: 0;

  @media ${device.tablet} {
    margin-top: -160px;
    padding-top: 200px;
  }

  & .section {
    width: 100%;
  }

  & .row {
    justify-content: center;
  }
`;

export const Main = styled.main`
  position: relative;
  &.main-content,
  &.main-section {
    background-color: transparent;
  }
`;
