/**
 *
 * QuestionsLinks
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { QuestionLink } from './style';

const QuestionsLinks = props => {
  const { testParts, updateQuestion, currentQuestion, answers } = props;
  let questionsCount = 0;
  const getQuestionAnswer = (category, questionNumber) => (answers[category][questionNumber] ? answers[category][questionNumber].toUpperCase() : null);
  return Object.entries(testParts).reduce((result, [category, testsCount]) => {
    for (let i = 1; i <= testsCount; i += 1) {
      questionsCount += 1;
      const questionNumber = questionsCount;
      const questionAnswer = getQuestionAnswer(category, i);
      result.push(
        <QuestionLink
          key={`q${questionNumber}${category}`}
          onClick={() => updateQuestion(questionNumber)}
          active={currentQuestion === questionNumber}
          complete={questionAnswer}
        >
          {questionNumber} {questionAnswer}
        </QuestionLink>,
      );
    }
    return result;
  }, []);
};

QuestionsLinks.propTypes = {
  testParts: PropTypes.object.isRequired,
  updateQuestion: PropTypes.func.isRequired,
  currentQuestion: PropTypes.number.isRequired,
  answers: PropTypes.object.isRequired,
};

export default QuestionsLinks;
