/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';

import languageProviderReducer from './containers/LanguageProvider/reducer';
import GamesProviderReducer from './containers/GamesPage/reducer';
import GameProviderReducer from './containers/GamePage/reducer';
import SignupProviderReducer from './containers/SignupPage/reducer';
import SignoutProviderReducer from './containers/SignoutPage/reducer';
import LoginProviderReducer from './containers/LoginPage/reducer';
import UpdateProfileProviderReducer from './containers/EditAccountPage/reducer';
import DashboardProviderReducer from './containers/DashboardPage/reducer';
import LandingSalesPageReducer from './containers/LandingCheckOutPage/reducer';
import history from './utils/history';
import BrainProfileProviderReducer from './containers/BrainProfilePage/reducer';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    language: languageProviderReducer,
    games: GamesProviderReducer,
    game: GameProviderReducer,
    signup: SignupProviderReducer,
    signout: SignoutProviderReducer,
    login: LoginProviderReducer,
    updateProfile: UpdateProfileProviderReducer,
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    dashboard: DashboardProviderReducer,
    landingCheckOutPage: LandingSalesPageReducer,
    brainProfile: BrainProfileProviderReducer,
    ...injectedReducers,
  });

  // Wrap the root reducer and return a new root reducer with router state
  const mergeWithRouterState = connectRouter(history);
  return mergeWithRouterState(rootReducer);
}
