import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { device } from '../../styles/constants';
import palette from '../../styles/palette';

const activeColor = '#EC5C0B';
const disableColor = '#EABEA6';
const disableTextColor = '#AEAEAE';

export const baseButton = css`
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  border: none;
  background: none;
  color: #1f2d44;
  padding: 10px 40px;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
  &:hover {
    cursor: pointer;
  }
  &[disabled] {
    pointer-events: none;
  }

  @media (max-width: 576px) {
    font-weight: 400;
  }

  @media ${device.tablet} {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    padding: 12px 40px;
  }
`;

export const noOutlineStatesStyles = css`
  &:hover {
    text-decoration: underline;
    color: #1f2d44;
    box-shadow: none;
  }
  &:active,
  &:focus {
    transition: none;
    color: ${activeColor};
    box-shadow: none;
    background: none;
    border-color: transparent;
  }
  &[disabled] {
    transition: none;
    color: ${disableTextColor};
    pointer-events: none;
  }
`;
const ButtonNoOutlineBlack = styled.button`
  ${baseButton};
  ${noOutlineStatesStyles};
`;

const outlineStatesStyles = css`
  &:hover {
    text-decoration: none;
    border-color: ${palette.accent};
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    color: ${palette.accent};
    background-color: ${palette.white};
  }

  &:active,
  &:focus {
    border-color: ${activeColor};
    background: none;
    box-shadow: none;
    transition: none;
    color: ${activeColor};
  }
  &[disabled] {
    border-color: ${disableTextColor};
    box-shadow: none;
    transition: none;
    color: ${disableTextColor};
  }
`;

const ButtonOutlinedBlack = styled.button`
  ${baseButton};
  border: 2px solid #1f2d44;
  ${outlineStatesStyles};
`;

const ButtonOutlinedWhite = styled.button`
  ${baseButton};
  border: 2px solid #1f2d44;
  color: white;
  border: 2px solid white;
  transition: all 0.3s ease-out;
  ${outlineStatesStyles};
`;

const filledStatesStyles = css`
  &:hover {
    color: white;
    box-shadow: 0px 12px 16px rgba(246, 129, 63, 0.25);
  }
  &:active,
  &:focus {
    color: white;
    border-color: ${activeColor};
    background: ${activeColor};
    box-shadow: none;
    transition: none;
  }
  &[disabled] {
    color: white;
    border-color: ${disableColor};
    background: ${disableColor};
    box-shadow: none;
    transition: none;
  }
`;

const ButtonFilledOrange = styled.button`
  ${baseButton};
  background: ${palette.accent};
  border: 2px solid ${palette.accent};
  color: white;
  ${filledStatesStyles};
`;

const ButtonLinkFilledOrangeLarge = styled(Link)`
  ${baseButton};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: ${palette.accent};
  border: 2px solid ${palette.accent};
  color: white;
  line-height: 19px;
  min-width: unset;
  width: 269px;
  ${filledStatesStyles};
  @media ${device.tablet} {
    width: 303px;
  }
`;

const filledWhiteStatesStyles = css`
  &:hover {
    color: ${palette.accent};
    box-shadow: 0px 12px 16px rgba(246, 129, 63, 0.25);
  }
  &:active,
  &:focus {
    color: white;
    border-color: ${activeColor};
    background: ${activeColor};
    box-shadow: none;
    transition: none;
  }
  &[disabled] {
    color: ${disableColor};
    border-color: ${disableColor};
    background: ${palette.white};
    box-shadow: none;
    transition: none;
  }
`;
const ButtonLinkFilledWhiteLarge = styled(Link)`
  ${baseButton};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: ${palette.white};
  border: 2px solid ${palette.accent};
  color: ${palette.accent};
  line-height: 19px;
  min-width: unset;
  width: 269px;
  ${filledWhiteStatesStyles};
  @media ${device.tablet} {
    width: 303px;
  }
`;

const ButtonFilledOrangeLarge = styled.button`
  ${baseButton};
  background: ${palette.accent};
  border: 2px solid ${palette.accent};
  color: white;
  width: 269px;
  ${filledStatesStyles};

  @media ${device.tablet} {
    width: 303px;
  }
`;

export {
  ButtonNoOutlineBlack,
  ButtonOutlinedBlack,
  ButtonFilledOrange,
  ButtonFilledOrangeLarge,
  ButtonOutlinedWhite,
  ButtonLinkFilledOrangeLarge,
  ButtonLinkFilledWhiteLarge,
};
