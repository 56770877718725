/*
 *
 * DashboardProvider constants
 *
 */

export const LOAD_BQ_DATA = 'app/dashboardPage/LOAD_BQ_DATA';
export const BQ_DATA_LOADED = 'app/dashboardPage/BQ_DATA_LOADED';
export const BQ_DATA_LOAD_ERROR = 'app/dashboardPage/BQ_DATA_LOAD_ERROR';

export const LOAD_WORKOUT_SESSION_DATA = 'app/dashboardPage/LOAD_WORKOUT_SESSION_DATA';
export const WORKOUT_SESSION_DATA_LOADED = 'app/dashboardPage/WORKOUT_SESSION_DATA_LOADED';
export const WORKOUT_SESSION_DATA_LOAD_ERROR = 'app/dashboardPage/WORKOUT_SESSION_DATA_LOAD_ERROR';

export const LOAD_TRAINING_CALENDAR_DATA = 'app/dashboardPage/LOAD_TRAINING_CALENDAR_DATA';
export const TRAINING_CALENDAR_DATA_LOADED = 'app/dashboardPage/TRAINING_CALENDAR_DATA_LOADED';
export const TRAINING_CALENDAR_DATA_LOAD_ERROR = 'app/dashboardPage/TRAINING_CALENDAR_DATA_LOAD_ERROR';
