import styled from 'styled-components';

const Button = styled.button`
  padding: 12px 34px;
  color: #fff !important;
  margin-top: 10px;
  display: inline-block;
  position: relative;
  background-color: #c87b58;
  z-index: 1;
  font-size: 16px;
  white-space: nowrap;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 7px 32px 0px;
  font-weight: 500;
  max-width: 100% !important;
  border-radius: 50px;
  border-width: 3px;
  border-style: solid;
  border-color: #c87b58;
  border-image: initial;
  transition: all 0.1s linear 0s;
  min-width: 200px;
  cursor: pointer;
  :hover {
    border-color: #f35710;
  }
  ::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: #c87b58;
    pointer-events: none;
    border-radius: 50px;
  }
  @media (min-width: 768px) {
    font-size: 23px;
    padding: 15px 50px;
    margin-top: 20px;
  }
`;

export default Button;
