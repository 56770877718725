import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg width={26} height={19} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1.5A1.5 1.5 0 011.5 0h23a1.5 1.5 0 010 3h-23A1.5 1.5 0 010 1.5zm0 8A1.5 1.5 0 011.5 8h23a1.5 1.5 0 010 3h-23A1.5 1.5 0 010 9.5zM1.5 16a1.5 1.5 0 000 3h23a1.5 1.5 0 000-3h-23z"
        fill={props.fill || '#1F2D44'}
      />
    </svg>
  );
}

export default SvgComponent;
