/**
 *
 * App.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 */

import React, { useEffect } from 'react';

import Router from '../../router';
import { useTrafficParamsSaver } from '../../utils/hooks/useTrafficParamsSaver';
import '../../assets/styles/index.scss';

import { setCookie } from '../../utils/cookies';

export default function App() {
  useEffect(() => {
    const { cid, reqid, pi_clickid, uid, sid, SID } = Object.fromEntries(new URLSearchParams(window.location.search));
    if (cid) {
      setCookie('cid', cid);
    }
    if (reqid) {
      setCookie('reqid', reqid);
    }
    if (pi_clickid) {
      setCookie('pi_clickid', pi_clickid);
    }
    if (uid) {
      setCookie('uid', uid);
    }
    if (sid || SID) {
      setCookie('sid', sid || SID);
    }
  }, []);
  useTrafficParamsSaver();
  return <Router />;
}
